export const $$const = {
    baseVar:{
        topHeight:56,//顶部的高度
        tabHeight:36,//tab的高度
        tbHeaderHeight:25,//表格标题的高度
        tbToolBarHeight:35,//表格工具条的高度
        jfAccessToken:'Jf-Access-Token',
        errorCode:'errorCode',//放到header中的，前端取的时候必须是小写，否则取不到，放入的时候可以不是小写
        downloadcode:'downloadcode'
    },
    sysCode:{
        _0000:'0000',//成功
        _0001:'0001',//token验证失败
        _0002:'0002',//没有传入token
        _0009:'0009',//token过期
        _0010:'0010',//非法访问
        _0014:'0014',//登录账号不存了还在操作
        _0015:'0015'//登录账号已被禁用了还在操作
    }

}
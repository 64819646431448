//表格加载完成事件
const GRIDLOADED="gridLoaded";
//面板影藏事件
const ONHIDDEN="onHidden";
//选择所有
const SELECTALL="selectAll";
//下拉面板显示
const SHOW="show";
//单选的时候单击行
const CLICKTABLEROW="clickTableRow";
//下拉框头部工具条编辑事件
const EDITHANDLER="editHandler";
//下拉框头部工具条刷新事件
const REFRESHHANDLER="refreshHandler";
//值改变事件
const ONCHANGE="comboGridOnChange";
//双向绑定用到的key
const updateKey='update:modelValue';
import {ref, computed, getCurrentInstance, reactive, toRefs, onMounted, defineComponent, watch, provide, nextTick} from 'vue';
const ComboGridHelper = defineComponent({
    name: "comboGrid",
    props: {
        modelValue: {//表示该自定义控件的值，必须和上面updateKey里面的名称保持一样
            type: String,
            default: ''
        },
        comboParam: {//接收的combogrid参数
            type: Object,
            default: function () {
                return {
                    disabled:false,
                    showToolBar:false,
                    multiple: false
                }
            }
        }
    },
    setup(props,context){
        const {proxy} = getCurrentInstance();
        const utils=proxy.utils;
        provide('gridTableOwner',proxy);
        let dataObj=reactive({
            visible:false,//popover的显示/影藏控制字段
            popoverRef:null,//popover引用对象
            comboGridRef:null,//gridTable引用对象
            comboId:'comboId',//下拉框默认id
            readOnly:false,//输入框是否只读（是否可以搜索）
            disabled:false,//是否禁用该组件
            txtField:"F_NAME",//下拉框显示字段名称，默认列F_NAME
            idField:"F_ID",//下拉框值字段名称，默认列F_ID
            comboText: "",//下拉显示文本
            searchFieldsArray:[],//参与搜索的字段
            gridParam: {//下拉框中表格的参数
                tbId:props.comboParam.comboId?props.comboParam.comboId:'comboId',
                showTitle:props.comboParam.showTitle?props.comboParam.showTitle:false,//默认不显示标题，除非传入参数明确要显示标题
                showToolBar:props.comboParam.showToolBar?props.comboParam.showToolBar:false,//默认不显示工具栏，除非传入参数明确要显示工具栏
                canPage: !props.comboParam.multiple,//如果下拉表格是多选的，那么不支持分页
                autoLoad:false,//默认不自动加载grid，在展示面板的时候再去加载grid
                //表格查询条件，将要把这些参数传入后台
                queryParam: Object.assign({}, {combo: "combo",searchText: ""}, props.comboParam),
                tbPagerInitParam:{//重写分页条
                    small:true,//让分页条变小一点
                    pagerCount:5,//默认是显示7个可操作页码，这里只能传入5到21之间的奇数
                    pageSize: 10,
                    layout:'prev, pager, next, jumper'//由于下拉框空间比较小，所以分页显示的东西变少了
                },
                modelMethod:props.comboParam.modelMethod//下拉的请求路径
            }
        });

        onMounted(async ()=>{
            await init(props.comboParam);//根据传入的参数进行默认参数覆盖
        });
        const init=async (params)=>{
            nextTick(async()=>{
                //如果调用层传入了值字段和文本字段，就用调用层指定的，没有传入就用默认的
                if(params.idField)dataObj.idField=params.idField;
                if(params.txtField)dataObj.txtField=params.txtField;

                if(params.searchFieldsArray){//如果自定义了搜索字段就采用自定义的，没有的话就采用表格所有字段参与搜索
                    dataObj.searchFieldsArray=params.searchFieldsArray;
                }else{//得到表格所有字段
                    dataObj.searchFieldsArray=dataObj.comboGridRef.tbCols.fields;
                }
                if(params.readOnly)dataObj.readOnly=params.readOnly;
                if(params.multiple)dataObj.readOnly=true;//如果是多选下拉框，则不允许下拉框搜索
                if(params.disabled)dataObj.disabled=params.disabled;//是否禁用下拉框
                //如果下拉框有值，但是没有显示文本，则发送请求到后台，用值取查询显示文本
                if(utils.$$str.isNotEmpty(params.modelMethod) && utils.$$str.isNotEmpty(props.modelValue) && utils.$$str.isEmpty(dataObj.comboText)){
                    await getTextById();
                }
                if(!params.maxHeight)params.maxHeight=200;//如果没有传入表格最大高度，则默认为200
                if(dataObj.comboGridRef)dataObj.comboGridRef.setMaxTbHeight(params.maxHeight);
                if(params.comboId)dataObj.comboId =params.comboId;
            })
        }
        //监控下拉框值改变
        watch(() => props.modelValue,async (newValue,oldValue) => {
                if ("" == newValue) {
                    dataObj.comboText = "";
                } else {
                    //在流程设计的时候，条件下拉框那里，一条分支设置过条件，打开另外一条分支不设置条件，
                    //然后在打开设置过条件的下拉框，就会出现下拉框有value，但是text没有了，所以要做如下的判断
                    if(utils.$$str.isNotEmpty(props.comboParam.modelMethod) && utils.$$str.isEmpty(dataObj.comboText)){
                        await getTextById();
                    }
                }
                //刚进入页面的时候，newValue为null，不用触发值改变事件
                if(null!=newValue)context.emit(ONCHANGE, newValue, oldValue,dataObj.comboId);
                //第一次进入的时候newValue为null，可以通过这点来判断以下，刚进入不需要验证;
                //由于验证要用到comboId，如果没有定义comboId，则不用验证，也就是说想要验证自定义控件，必须传入comboId
                //试想一下，如果一个页面有多个下拉控件，这些下拉控件都不指定comboId，那么都会叫做默认的comboId，怎么验证呢？
                // 所以要想下拉控件具备验证功能，就必须指定comboId
                if(newValue!=null && props.comboParam.comboId){
                    // proxy.$parent.$parent.validate(newValue);//验证所有字段
                    //有可能在明细中也用了下拉，那么就没有formRef了，所以这里要判断一下，只针对form表单的下拉控件进行验证
                    if(props.comboParam.formRef)props.comboParam.formRef.validateField(dataObj.comboId);//值验证该控件id的字段
                }
            })

        //如果传入的参数发生了改变，需要重新初始化参数，否则会有问题，比如代码生成那里，选择了子表名称之后，
        // 点击主表关联字段，下拉框在show的时候，发现tbName始终为空，然而明明外层用computed已经改变了tbName，
        // 但是在combogrid的gridparam.queryparam中，接收到外面传过来的tbName始终是空，仿佛外界即便用computed
        // 向combogrid传参不会自动更新dataObj.gridParam.queryParam，所以下面就手动去更新一下该参数
        watch(() => props.comboParam,async (newValue,oldValue) => {
            await init(newValue);
            dataObj.gridParam.queryParam=Object.assign({}, {combo: "combo",searchText: ""}, props.comboParam);
        })

        //根据下拉框的值找出该值的显示文本
        const getTextById=async()=>{
            if (utils.$$str.isNotEmpty(props.modelValue)) {
                if (props.comboParam.multiple) {
                    //对于多选的下拉框，text文本是从后台查询构造好了发送到前台的。
                    if(props.comboParam.mulComboText)dataObj.comboText = props.comboParam.mulComboText;
                } else {
                    //不是多选的下拉框，显示的文本是根据id动态查询出来的
                    if(props.comboParam.content){//如果是静态数据下拉框，就循环下拉框的内容找到要显示文本，不用去后台根据值查询显示文本
                        let item=props.comboParam.content.find((row) => row[dataObj.idField] == props.modelValue);
                        if(item)dataObj.comboText = item[dataObj.txtField];
                    }else{//不是静态数据下拉框
                        //在一个页面有很多下拉框的时候，如果后台把下拉框值对应的文本一次性的查询出来传递给前台会节约很多的资源，不会发多次请求了
                        //当然，如果你不传递给前台也可以，就走else，一条一条的到数据库查询
                        if(props.comboParam.comboText){
                            dataObj.comboText = props.comboParam.comboText;
                        }else{
                            //如果没有传入下拉框值对应的显示文本，则需要根据下拉框值去数据库找该值对应的显示文本
                            //下拉框请求路径配置的是：模块/url/combo，我们要取出模块url
                            // let modelPath=props.comboParam.modelMethod.substr(0,props.comboParam.modelMethod.lastIndexOf('/'));
                            let modelPathArr=props.comboParam.modelMethod.split('/');
                            let modelPath="/"+modelPathArr[1];
                            let params= Object.assign({comboId:dataObj.comboId},props.comboParam,{ id: props.modelValue },{comboType:'comboGrid'});
                            //这里其实可以直接请求load方法，只不过不是每个下拉框的显示字段都叫做name，所以干脆在后台抽出一个getTextById来处理显示文本，也可以在这里处理特殊任务
                            const res=await utils.$$api.postRequest({url: modelPath + "/getTextById",params: params});
                            if(res.result)dataObj.comboText = res.comboText;
                        }
                    }
                }
            }
        }
        //表格加载完毕事件，由gridTable调用，这里再向外抛出加载完毕事件,这里的tbId就是comboId
        const gridLoaded=(response,tbId)=>{
            context.emit(GRIDLOADED, response,tbId);
        }
        //下拉框搜索事件
        const inputChange=(value)=>{
            if (value == "")context.emit(updateKey,'');//如果清空了下拉框，则触发事件改变下拉框的值
            if(props.comboParam.content){//静态数据下拉框
                let tempContent=props.comboParam.content.slice(0);//深拷贝一份静态数据，否则会影响原数组
                props.comboParam.content.forEach((row, index)=> {
                    //查询所有参与搜索的字段是否包含输入的搜索值
                    let item=dataObj.searchFieldsArray.find((field) => row[field].indexOf(value)>-1);
                    //不包含搜索值，则去掉该项
                    if(!item)tempContent.splice(index,1);
                });
                dataObj.comboGridRef.setTbData(tempContent);
            }else{//带上搜索的内容到后台取查询，重新加载表格
                dataObj.gridParam.queryParam.searchText = value;
                dataObj.comboGridRef.queryHandler(true);
            }
        }
        //点击行事件，该事件由gridTable触发
        //注意：点击行会触发触发clickTableRow事件，进而会触发toggleRowSelection事件，那么就会触发selectionChange事件，
        //但是如果仅仅点击复选框，只会触发selectionChange事件，不会触发点击行事件clickTableRow
        const clickTableRow=async(options)=>{
            let row = options.row;
            if (!props.comboParam.multiple) {//可以多选的时候必须点击复选框，点击行不处理
                context.emit(CLICKTABLEROW, row,dataObj.comboId);//向外抛出点击行事件
                if (row[dataObj.txtField] != dataObj.comboText) {//如果当前点击行和输入框内容不一样，则用点击行的内容替换掉输入框内容，同时清空搜索文本
                    dataObj.comboText = row[dataObj.txtField];
                }
                //用点击行的值作为下拉框的值，触发事件，改变下拉框的值
                await context.emit(updateKey,row[dataObj.idField]);
                dataObj.visible=false;
            }else{
                //对于多选表格，前面显示有checkbox，单击行的时候要切换checkbox的选中状态，就会触发select事件，
                // 在select事件发生改变的时候会触发下面的selectionChange事件，在那里面会处理comboText，
                // 所以这里没有处理多选的comboText，多选的comboText交给了selectionChange事件处理
                //值得注意的是：由于在这里处理了单选的清空，但是由于单击行也会触发selectionChange事件，
                //那么在selectionChange那里就要判断一下了，如果是单选的情况，就不处理了。
            }

        }
        //注意：这里只处理多选的情况，单选上面的clickTableRow单击行那里已经处理了
        const selectionChange=(options)=>{
            if (props.comboParam.multiple){
                let selection = options.selection;//表格被选择的行集合
                let _comboText = "", _comboValue = "";
                selection.forEach((row)=>{
                    _comboValue = _comboValue + row[dataObj.idField] + ",";
                    _comboText = _comboText + row[dataObj.txtField]+ ",";
                });

                _comboValue = _comboValue.trim().substr(0, _comboValue.trim().length - 1);//去掉最后的逗号
                _comboText = _comboText.trim().substr(0, _comboText.trim().length - 1);//去掉最后的逗号
                dataObj.comboText = _comboText;
                context.emit(updateKey,_comboValue);//触发事件，改变下拉框的值
            }
        }
        //只有多选才会有这个事件
        const selectAll=(options)=>{
            selectionChange(options);
        }
        //下拉面板影藏事件
        const hide=()=>{
            let tbData = dataObj.comboGridRef.getTbData();
            //当下拉面板影藏，搜索文本有值的时候，表示有搜索到记录，那么就取表格第一条记录
            if (tbData.length != 0  && utils.$$str.isNotEmpty(dataObj.gridParam.queryParam.searchText)) {
                dataObj.comboText = tbData[0][[dataObj.txtField]];
                context.emit(updateKey,tbData[0][dataObj.idField]);//触发事件，改变下拉框的值
            } else if (tbData.length == 0) {//影藏面板，表格没有值，不管是否在搜索，下拉框都不应该有值
                dataObj.comboText = "";
                context.emit(updateKey,'');//触发事件，改变下拉框的值
            }
            context.emit(ONHIDDEN, {comboValue:props.modelValue,comboId:dataObj.comboId});//触发关闭面板事件
            dataObj.gridParam.queryParam.searchText = "";//关闭面板，清空搜索框的值
        }
        //下拉面板显示事件
        const show=async()=>{
            if(props.comboParam.content){//静态数据下拉框，直接赋值给下拉框
                dataObj.comboGridRef.setTbData(props.comboParam.content);
            }else{//不是静态表格，则发送请求去获取数据
                let tbData = dataObj.comboGridRef.getTbData();
                //如果表格没有数据，那么可能是还没有去加载，那么就发送一次请求加载表格数据
                if(tbData.length==0)await dataObj.comboGridRef.queryHandler();
                //如果下拉框有值，则把下拉框中对应行给选择上，只会处理第一页，如果值在第二页，那么不会跳转到第二页把对应行给选择上，不会
                if(utils.$$str.isNotEmpty(props.modelValue))selectByComboValue();
            }
            context.emit(SHOW, {comboId:dataObj.comboId});//触发显示面板事件
        }
        //根据combovalue选中对应行，如果表格是分页的，而当前页没有找到对应的comboValue，则不选择表格任何行
        const selectByComboValue=()=>{
            let tbRef=dataObj.comboGridRef.tbRef;
            let tbData = dataObj.comboGridRef.getTbData();
            tbRef.clearSelection();//清空表格选中项
            tbData.forEach((row)=> {
                if (props.comboParam.multiple) {//多选，有下拉框，则把下拉框给选中
                    if (props.modelValue.indexOf(row[dataObj.idField]) > -1) tbRef.toggleRowSelection(row,true);
                } else {
                    if (row[dataObj.idField] == props.modelValue) tbRef.setCurrentRow(row);//没有复选框选中行变色
                }
            });
        }
        //供业务层重新传参查询下拉表格
        const setQueryParams=(appendParams)=>{
            dataObj.gridParam.queryParam = Object.assign(dataObj.gridParam.queryParam, appendParams);
            dataObj.comboGridRef.queryHandler(true);
            dataObj.comboText = "";
        }

        //清空输入框事件，其实就是把下拉框选择的值清空
        const inputClear=async ()=>{
            dataObj.comboText = "";
            context.emit(updateKey,'');
            dataObj.gridParam.queryParam.searchText = "";
            await refreshHandler();
        }
        //----------------------------toolbar--------------------------
        //下拉框编辑按钮事件（没有实现任何内容，交给业务模块去实现）
        const editHandler=()=>{
            context.emit(EDITHANDLER, {comboId:dataObj.comboId});
        }
        //刷新下拉框，重新加载下拉表格
        const refreshHandler=async()=>{
            await dataObj.comboGridRef.queryHandler(true);
            let tbData = dataObj.comboGridRef.getTbData();
            context.emit(REFRESHHANDLER, {tbData:tbData,comboId:dataObj.comboId});
        }
        //--------------------------------------------------------------
        //得到下拉框中表格的引用
        const getTbInst=()=>{
            return dataObj.comboGridRef;
        }
        //下拉表格是否禁用
        const setDisable=(val)=>{
            dataObj.disabled=val;
        }
        return{
            ...toRefs(dataObj),getTextById,gridLoaded,inputChange,clickTableRow,selectionChange,
            selectAll,hide,show,setQueryParams,selectByComboValue,inputClear,editHandler,
            refreshHandler,getTbInst,setDisable
        }
    },
    components: {
         
    }
});
export default ComboGridHelper;
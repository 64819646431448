const SET_ENCRYPTDATA = 'SET_ENCRYPTDATA';//设置是否加密传输数据
const SET_MSG_RECEIVE_PERMISSION = 'SET_MSG_RECEIVE_PERMISSION';//设置是否具有接收消息的权限
const SET_CHANGE_PERSONALINFO_PERMISSION = 'SET_CHANGE_PERSONALINFO_PERMISSION';//设置是否具有修改个人信息的权限
const SET_CURROUTE = 'SET_CURROUTE';//设置当前路由
const others = {
    state: {
        encryptData: false,//是否加密传输数据
        hasMessageReceivePermission:false,//是否具有接收消息的权限（是否显示top中消息中心）
        hasChangePersonalInfosPermission:false,//是否具有修改个人信息的权限（是否显示top中下拉：修改个人信息）
        curRoute:''//当前路由
    },
    mutations: {
        [SET_ENCRYPTDATA](state, encryptData) {
            state.encryptData = encryptData;
        },
        [SET_CURROUTE](state, curRoute) {
            state.curRoute = curRoute;
        },
        [SET_MSG_RECEIVE_PERMISSION](state, permission) {
            state.hasMessageReceivePermission = permission;
        },
        [SET_CHANGE_PERSONALINFO_PERMISSION](state, permission) {
            state.hasChangePersonalInfosPermission = permission;
        }
    },
    actions: {
        // 设置是否加密传输数据
        configEncryptData({commit}, encryptData) {
            commit(SET_ENCRYPTDATA, encryptData)
        },
        // 设置当前路由
        configCurRoute({commit}, curRoute) {
            commit(SET_CURROUTE, curRoute)
        },
        // 设置是否具有接收消息的权限
        configMsgReceivePermission({commit}, permission) {
            commit(SET_MSG_RECEIVE_PERMISSION, permission)
        },
        // 设置是否具有修改个人信息的权限
        configChangePersonalInfoPermission({commit}, permission) {
            commit(SET_CHANGE_PERSONALINFO_PERMISSION, permission)
        }
    },
    getters: {
        encryptData: state => state.encryptData,
        hasMessageReceivePermission: state => state.hasMessageReceivePermission,
        hasChangePersonalInfosPermission: state => state.hasChangePersonalInfosPermission,
        curRoute: state => state.curRoute
    }
}
export default others
const OSS = require('ali-oss');
import {$$tools} from './tools';
import {$$str} from './string';
const config = {
    region: 'cn-shanghai',
    accessKeyId: 'LTAI5tMF9QgmbLpRyf9kEDqW',
    accessKeySecret: 'Qh6Kaid7QAvxtchpAunbbRll41tW7U',

    // accessKeyId: 'STS.NUhNJ7NJ2LBjaLSf8pCkm4qWn',
    // accessKeySecret: '2wq8TBtnUW7AB7737AMiMC2rTXrbsERQ38C4HhxSm7YN',
    // stsToken:'CAISkQJ1q6Ft5B2yfSjIr5bdBfCDo5UT+4CBY2rigjglT+RB27T8jDz2IHhJfHZrAO8cv/k+lWxV5vcelrBaT55UWEr/UfBZ9Y9K9A6bZI7Mkse+4Kcf0EhjmvAETEyV5tTbRsmkZvG/E67fRjKpvyt3xqSABlfGdle5MJqPpId6Z9AMJGeRZiZHA9EkTWkL6rVtVx3rOO2qLwThj0fJEUNsoXAcs25k7rmlycDugXi3zn+Ck7FJ/Nuqfcn5MZg1Ys4kCe3YhrImKvDztwdL8AVP+atMi6hJxCzKpNn1ASMKu0jebLGNrYc+cV4oOvVqSvJe3fH4lOxlvPfPjI3s0AtIMPtSVynWT4ut29DU1Dxb1jeO6NwagAEhwWr30vsoIUcS+2/d//cIm7IF+drTxz0EoFTzPujDoFTei3ZuqwyCRPnmrVl2N+wyC0L2L9pUQYgdecH3ojjRV7zT5g/aOrHWWkXmYsvL8Lqd6gJR9tGNJ9+PkneQVeyJ7GO3l5y/31rmbO2qsFtEHcz6qtjJ0gJgh8+Tw9N4Ag==',

    bucket: 'shineray-dev-suxiang',
    endpoint:'oss-cn-shanghai.aliyuncs.com'
}
let $$ossInst= new OSS({
    region: config.region,
    accessKeyId: config.accessKeyId,
    accessKeySecret: config.accessKeySecret,
    // stsToken: config.stsToken,//如果是采用临时key和secret的方式，那么就必须要有stsToken，否则上传会失败，如果不是临时的，则不用传这个参数
    bucket: config.bucket,
    endpoint: config.endpoint
})

export function ossUpload (file,editor) {
    return new Promise((resolve, reject) => {
        let uploadName='test/editor/'+$$str.guid()+'-'+file.name;
        $$ossInst.multipartUpload(uploadName, file, {
            progress: function(p) {//p进度条的值
                // that.showProgress = true;
                // that.progress = Math.floor(p * 100);
            }
        }).then(async ({res, name}) => {
            if (res && res.status == 200) {//console.log(`阿里云OSS上传图片成功回调`, res, url, name);
                let serverPath=res.requestUrls[0];
                //tmd，有时候上传返回的结果后面带上了?uploadId
                if(serverPath.indexOf('?')>-1)serverPath=res.requestUrls[0].substr(0,res.requestUrls[0].indexOf('?'));
                editor.cmd.do('insertHTML', `<img src="${serverPath}" alt="">`);
            }else{
                $$tools.info({message: (res.msg?res.msg:"上传失败!")});
            }
            resolve(res);
        }).catch((err) => {
            console.log(`阿里云OSS上传图片失败回调`, err);
            reject({result:false});
        });
    })
}
export default $$ossInst;
import {$$post} from "@/utils/axios";
import {$$lghdUtils} from "@/utils/lghdUtils";
import utils from "./jh.core";
export default {
    //发送post请求
    postRequest:async (options)=>{
        return await $$post({ url: options.url, params: options.params });
    },
    //动态生成id
    getNewId:async(options)=>{
        let res = await $$post({ url: "/utils/getNewId", params: options });
        return res.id;
    },

    //关于系统页面数据
    aboutInfo:async (params)=>{
        return await $$post({ url: "/utils/aboutInfo", params: params });
    },
    //其实这里可以不用async await，因为在调用的时候用的async await，所以这里只需要是一个promise即可。
    //以后万一这里需要用到同步呢，所以还是写上吧。
    //机构数据
    corpTreeData:async (params)=>{
        let res = await $$post({ url: "/corp/tree", params: params });
        return res.data;
    },
    //机构列表树
    corpListTreeData:async (params)=>{
        return await $$post({ url: "/corp/corpListTreeData", params: params });
    },

    //角色数据
    roleData:async (params)=>{
        params = Object.assign({}, {type:'roleSelectData'}, params);
        let res = await $$post({ url: "/role/custom", params: params });
        return JSON.parse(res.roleSelectData);
    },
    //----------------登录----------------
    //登录初始化数据
    loginInitData:async ()=>{
        return await $$post({ url: "/user/loginInit"});
    },
    //用户登录
    login:async (params)=>{
        return await $$post({ url: "/user/login", params: params });
    },
    //退出登录
    loginOut:async (params)=>{
        return await $$post({ url: "/user/loginOut", params: params });
    },
    //加载个人信息请求
    personalInfos:async (params)=>{
        return await $$post({ url: "/changePersonalInfos/personalInfos", params: params });
    },
    //修改密码
    changePassword:async (params)=>{
        return await $$post({ url: "/user/changePassword/changePassword", params: params });
    },
    //修改个人其它信息
    changeOtherInfo:async (params)=>{
        return await $$post({ url: "/user/changeOtherInfo", params: params });
    },
    //加载用户菜单
    loadUserMenus:async (params)=>{
        return await $$post({ url: "/roleMenu/userMenus", params: params });
    },
    //加载指定角色菜单
    loadRoleMenu:async (params)=>{
        return await $$post({ url: "/roleMenu/load", params: params });
    },
    //保存角色菜单
    saveRoleMenu:async (params)=>{
        return await $$post({ url: "/roleMenu/save", params: params });
    },

    //构建树形系统数据库表
    tableTreeData:async (params)=>{
        return await $$post({ url: "/genCode/tree", params: params });
    },
    //生成代码
    genCode:async (params)=>{
        let res = await utils.$$post({ url: "/genCode/genCode", params: params });
        return res;
    },
    //消息custom请求
    messageCustom:async (params)=>{
        return await $$post({ url: "/message/custom", params: params });
    },

    //------------------------------老公货嘀运营管理平台----------------------------------
    buildProvinceCityAreaTreeData:async (params)=>{
        return await $$post({ url: "/provinceCityArea/buildProvinceCityAreaTreeData", params: params});
    },
    getProvinces:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getProvinces", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getCitiesByProvince:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getCitiesByProvince", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },


    getAllCities:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getAllCities", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getCitiesByCysId:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getCitiesByCysId", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getCitiesByChannelId:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getCitiesByChannelId", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getAreasByCity:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getAreasByCity", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getAreasByProvince:async (params)=>{
        let result=await $$post({ url: "/provinceCityArea/getAreasByProvince", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getCysByChannelId:async (params)=>{
        let result=await $$post({ url: "/cys/getCysByChannelId", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    deleteFile:async (params)=>{
        const aloneServerInfo=utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
        let result=await $$post({ url: "/deleteFile", urlPre: aloneServerInfo, params: params});
        return result;
    },
    getFleetsByCys:async (params)=>{
        let result=await $$post({ url: "/fleet/getFleetsByCys", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,cityName:item.cityName,yqm:item.yqm,cysId:item.cysId,disabled:item.flag==1});
        })
        return data;
    },
    // getCarsByFleet:async (params)=>{
    //     let result=await $$post({ url: "/car/getCarsByFleet", params: params});
    //     let data=[];
    //     result.resultData.forEach((item,index)=>{
    //         data.push({value:item.id,label:item.carNum,disabled:item.flag==1});
    //     })
    //     return data;
    // },
    // getCarsByChannel:async (params)=>{
    //     let result=await $$post({ url: "/car/getCarsByChannel", params: params});
    //     let data=[];
    //     result.resultData.forEach((item,index)=>{
    //         data.push({value:item.id,label:item.carNum,disabled:item.flag==1});
    //     })
    //     return data;
    // },
    getDriversByFleet:async (params)=>{
        let result=await $$post({ url: "/driver/getDriversByFleet", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.flag==1});
        })
        return data;
    },
    getDriversByChannel:async (params)=>{
        let result=await $$post({ url: "/driver/getDriversByChannel", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.flag==1});
        })
        return data;
    },
    getCx:async (params)=>{
        let result=await $$post({ url: "/cx/getCx", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    // getGoodsType:async (params)=>{
    //     let result=await $$post({ url: "/goodsType/getGoodsType", params: params});
    //     return $$lghdUtils.dealSelectData(result.resultData);
    // },
    getCustomers:async (params)=>{
        let result=await $$post({ url: "/customer/getCustomers", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.flag==1});
        })
        return data;
    },
    // getCustomerAddressByCustomerId:async (params)=>{
    //     let result=await $$post({ url: "/customerAddress/getCustomerAddressByCustomerId", params: params});
    //     let data=[];
    //     result.resultData.forEach((item,index)=>{
    //         data.push({value:item.id,label:item.address,item:item});
    //     })
    //     return data;
    // },
    getPersons:async (params)=>{
        let result=await $$post({ url: "/person/getPersons", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item});
        })
        return data;
    },
    getRoles:async (params)=>{
        let result=await $$post({ url: "/role/getRoles", params: params});
        return $$lghdUtils.dealSelectData(result.resultData);
    },
    getSaleBillPageCompData:async (params)=>{
        let result=await $$post({ url: "/saleBill/pageCompData", params: params});
        return result;
    },
    // getCustomerQuotationByChannelId:async (params)=>{
    //     let result=await $$post({ url: "/customerQuotation/getCustomerQuotationByChannelId", params: params});
    //     let data=[];
    //     result.resultData.forEach((item,index)=>{
    //         data.push({value:item.id,label:item.name,item:item,disabled:item.forbiddenFlag==1});
    //     })
    //     return data;
    // },
    getProjectSaleBills:async (params)=>{
        let result=await $$post({ url: "/saleBill/getProjectSaleBills", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.flag==1});
        })
        return data;
    }
}
import {createApp,ref} from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element';
import installJhComponent from './plugins/jhComponent';
import "./assets/iconfont.css" ;
import utils from './utils/jh.core.js';
import validForbid from './utils/rule.js';
const config = require('./utils/config.js');
import router from './router';
import store from './store';
import axios from 'axios';
import {export_json_to_excel,export_mul_head_excel} from './excel/Export2Excel.js';

(async () => {
    const app=createApp(App)
        .use(store)
        .use(router);
    installElementPlus(app,ref);
    installJhComponent(app);
    utils.$$tools.initCtx(app);
    config.bindAppProps(app,[
        {key:'$axios',value:axios},
        {key:'utils',value:utils},
        {key:'validForbid',value:validForbid},
        {key:'excelUtils',value:export_json_to_excel},
        {key:'exportMulHeadExcel',value:export_mul_head_excel}
    ]);
    await router.isReady();
    app.mount('#app');

    app.directive('selectLoadMore',{
        mounted (el, binding) {
            // 获取element-ui定义好的scroll盒子 el-select-dropdown__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default
            // const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
            const SELECTWRAP_DOM = document.querySelector('.el-scrollbar__wrap');
            SELECTWRAP_DOM.addEventListener('scroll', function () { console.log(111);
                /**
                 * scrollHeight 获取元素内容高度(只读)
                 * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
                 * clientHeight 读取元素的可见高度(只读)
                 * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
                 * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
                 */
                if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
                    binding.value()
                }
            },false)
        }
    })
})();

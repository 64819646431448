import {ref, reactive, toRefs, onMounted, computed, getCurrentInstance, provide, nextTick, defineComponent, markRaw, createApp} from 'vue'
const DialogHelper = defineComponent({
    name: "Dialog",
    setup(props,context){
        let {proxy}=getCurrentInstance();//当前dialog组件实例代理
        const utils=proxy.utils;
        provide('dialogInst', proxy);
        let modelEngineParams=proxy.$params;
        let dataObj=reactive({
            customClass:modelEngineParams.dialogDiv+"_class",//dialog的自定义class，方便关闭的时候从body上移除
            modelEngineRef:null,//当前dialog采用的引擎ref，系统自动赋值
            modelEngine:null,//当前dialog采用的引擎
            modelEngineParams:modelEngineParams,//外界调用dialog的参数，此参数将传给引擎
            fullscreenLoading: false,//是否显示正在加载loadding对话框
            dialogOptions: {
                title: "提示",//dialog标题
                dWidth: "60%",//dialog内容默认宽度
                contentHeight: '',//dialog内容默认高度
                showFooterBtn: false,//是否显示dialog底部的确认和关闭按钮
                fullscreen: true,//是否全屏显示dialog
                closeModel: false,//点击蒙层是否就关闭dialog
                closeDestroy: true//弹出框关闭了是否就销毁
            },
            dialogVisible: false,//dialog是否影藏
        })

        onMounted(()=>{
            //构建dialog的基本属性：标题title、fullscreen是否全屏
            let fixProp={title:(modelEngineParams.title?modelEngineParams.title:'弹窗'),fullscreen:(modelEngineParams.fullscreen?modelEngineParams.fullscreen:false)};
            //如果传入了业务模块，而且业务模块指定了title，则采用业务模块的title属性作为dialog的标题
            if(modelEngineParams.modelComp && modelEngineParams.modelComp.title){
                fixProp.title=modelEngineParams.modelComp.title;
            }
            //如果传入了业务模块，而且业务模块指定了fullscreen，则采用业务模块的fullscreen属性来决定是否全屏dialog
            if(modelEngineParams.modelComp && modelEngineParams.modelComp.fullscreen){
                fixProp.fullscreen=modelEngineParams.modelComp.fullscreen;
            }
            dataObj.dialogOptions = Object.assign({}, dataObj.dialogOptions,modelEngineParams,fixProp);//合并处理后的参数

            let modelType=modelEngineParams.modelType?modelEngineParams.modelType:modelEngineParams.modelComp.modelType;
            if(!modelType || modelType=='list'){//弹出的是列表或者其它组件
                dataObj.modelEngine=modelEngineParams.modelComp;
                dataObj.dialogOptions.fullscreen=true;
                //当弹出框包含列表的时候，不知道为啥必须设置弹出框内容高度，否则列表不能正常渲染，但是卡片可以
                if(!dataObj.dialogOptions.contentHeight){
                    dataObj.dialogOptions.contentHeight='620px';
                    // dataObj.dialogOptions.contentHeight='520px';
                }
            }else{
                if('card'===modelType){//如果想要弹出的是卡片，则用卡片引擎来包裹住要弹出的内容
                    import("@/components/base_comp/CardEngine").then(component=>{
                        dataObj.modelEngine=component.default;
                    });
                } if('link'===modelType){//如果想要弹出的是链接，则用LinkComponent组件来包裹住要弹出的内容
                    import("@/components/base_comp/LinkComponent").then(component=>{
                        dataObj.modelEngine=component.default;
                    });
                }
            }
        })
        //弹出框底部确认、取消处理动作处理
        const buttomHandler=(operType)=>{
            if (modelEngineParams.ownerComp && modelEngineParams.ownerComp[operType]){
                let aa=modelEngineParams.ownerComp[operType](dataObj.modelEngineRef);
                aa.then(res=>{
                    if(res){//把dialog包含的组件作为参数传给方法
                        dataObj.dialogVisible=false;
                    }
                })
            }else{
                //不能自己调用doClose方法，否则会出问题，还是交给elementui自己去关闭吧
                dataObj.dialogVisible=false;
            }
        }
        //关闭动作处理
        const doClose=()=>{
            document.body.removeChild(document.getElementById(modelEngineParams.dialogDiv));
            let divs=document.getElementsByClassName(modelEngineParams.dialogDiv+"_class");
            for(let i=0;i<divs.length;i++){
                if(divs[i].parentNode)document.body.removeChild(divs[i].parentNode);
            }
            if (modelEngineParams.ownerComp && modelEngineParams.ownerComp['cancelHandler']){
                modelEngineParams.ownerComp['cancelHandler'](dataObj.modelEngineRef)
            }
        }
        //dialog关闭事件
        const close=()=>{}
        //dialog关闭动画结束事件，在close后执行
        const closed=()=>{doClose();}
        //dialog打开事件
        const open=()=>{}
        //dialog打开之后，但还未显示给用户看时的事件,在open之后执行
        const opened=async()=> {}
        return{
            ...toRefs(dataObj),doClose,close,closed,open,opened,buttomHandler
        }
    },
    components: {}
});
export default DialogHelper;
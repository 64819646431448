<template>
    <div class="dialog">
        <el-dialog
                :custom-class="customClass" top="10vh"
                :title="dialogOptions.title"
                v-model="dialogVisible"
                :width="dialogOptions.dWidth"
                :fullscreen="dialogOptions.fullscreen"
                :close-on-click-modal="dialogOptions.closeModel"
                destroy-on-close
                @opened="opened"
                @open="open"
                @close="close"
                @closed="closed"
                v-loading.fullscreen.lock="fullscreenLoading"
                element-loading-text="拼命加载中..."
                :append-to-body="true"
        >


            <div :style="{height:dialogOptions.contentHeight,overflow: 'auto'}">
                <slot name="content">
                    <component ref="modelEngineRef" :is="modelEngine" :engineParams="modelEngineParams"></component>
                </slot>
            </div>
            <template #footer>
                <span v-if="dialogOptions.showFooterBtn">
                    <slot name="footerBtn">
                      <el-button @click="buttomHandler('cancelHandler')">关闭</el-button>
                      <el-button type="primary" @click="buttomHandler('sureHandler')">确 定</el-button>
                    </slot>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import DialogHelper from "./DialogHelper.js";
    export default DialogHelper;
</script>

<style scoped>
    .dialog{
        height: 100%;
    }
</style> 

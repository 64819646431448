import {$$str} from "@/utils/string";
import {$$post,$$upload,$$downLoad} from "@/utils/axios";
import {$$tools} from "@/utils/tools";
import {$$dialog} from "@/utils/dialog";
import {$$component} from "@/utils/component";
import $$api from "@/utils/api";
import {$$const} from "@/utils/const";
import {$$lghdUtils} from "@/utils/lghdUtils";
import $$map from "@/utils/map";
import $$ossInst from "@/utils/aliyun.oss";

export default {
    $$str, $$post,$$upload,$$downLoad,$$tools, $$dialog,$$api,$$component,$$const,$$lghdUtils,$$map,$$ossInst
}


import {createRouter, createWebHistory} from 'vue-router'
import utils from '@/utils/jh.core.js';
import store from '@/store'; // 引入store
const Welcome = () => import('@/views/sysviews/welcome/Welcome');

//路由中固定项，其它全部是动态添加进来放到/中作为二级路由
export const userRoutes = [
    {path: '/login', name: 'login', component: () => import('@/Login')},
    {
        path: '/',
        name: 'Main',
        redirect: '/welcome',
        meta: {icon: 'el-icon-message', title: '主界面'},//顶部tab显示的内容就是路由meta中的title属性
        component: () => import('@/components/layout/Main'),
        children: []
    },
    {path: '/404', name: '404', component: () => import('@/views/sysviews/404/404')}
];

let router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: userRoutes
});

//构建路由信息
export const routObj = {
    //根据菜单动态构建二级路由信息
    buildRoutes(userMenus,routeMap){
        for (let i = 0; i < userMenus.length; i++) {
            if (userMenus[i].children) {
                this.buildRoutes(userMenus[i].children, routeMap);//如果不是叶子节点，递归调用
            } else {
                let routItem =this.buildLeafRouteItemInfo({//根据叶子菜单构建出路由对象信息
                    id: userMenus[i].id,
                    page: userMenus[i].page,
                    path: userMenus[i].path,
                    icon: userMenus[i].meta.icon,
                    title: userMenus[i].meta.title,
                    prop: userMenus[i].meta.prop,
                    show: userMenus[i].meta.show,
                    type: userMenus[i].meta.type
                });
                routeMap.set(routItem.path, routItem);//把所有动态路由放一份到map集合中
                userRoutes[1].children.push(routItem);//把所有动态路由全部添加到/中作为二级路由
            }
        }
    },
    //构建路由对象信息（必须是叶子菜单）
    buildLeafRouteItemInfo(options) {
        let obj = {path: options.path, name: options.id};
        obj.meta = {//把菜单信息放到meta对象中
            icon: options.icon,
            title: options.title,
            prop: options.prop,
            show: options.show,
            type: options.type,
            page: options.page
        };
        if (options.page && !options.page.startsWith("http")){//为路由对象创建组件属性
            obj.component = async () => await import(`@/views${options.page}`);
        }else{//page如果以http打头表示是链接,本来不需要组件的，但是vue3，你不创建组件要报错，所以这里就用一个辅助组件来帮忙
            obj.component = async () => await import('@/components/base_comp/Util');
        }
        return obj;
    },
    //把所有portal和/welcome进行路由共享
    buildPortalsToRoute(portals,routeMap){
        let portalComponents = {};
        let welComeRoute = {//构建welcome路由
            path: '/welcome',
            name: 'welcome',
            meta: {icon: 'el-icon-menu', title: '首页'},
            components: Object.assign({}, {default: Welcome}, portalComponents)
        };
        routeMap.set(welComeRoute.path, welComeRoute);
        userRoutes[1].children.push(welComeRoute);
        //上面把所有动态路由已经放入到了routeMap中了，以下是放最上方定义的固定路由，经过这样的步骤，routeMap中就有所有的路由信息了，包含一级、二级路由
        userRoutes.forEach(item=>routeMap.set(item.path, item));
    },

    //根据页面地址懒加载组件
    getComponentByPage(page) {
        return () => import(`@/views${page}`);
    }
};

//路由守卫，全局拦截。
router.beforeEach(async (to, from, next) => {

    if (to.path == '/login' || to.path == '/register') {
        //如果当前访问/login或者/register，需要判断是否登录过了，如果没有登录，则放行，让其访问/login或者/register；
        //如果登录过了，则需要再次路由守卫，直到找到路由/，然后放行
        sessionStorage.getItem("userInfo") == null?next():next('/');
    } else {
        //如果不是访问/login或者/register，首先就要判断是否登录，没有登录，则提示需要登录，然后再次进入路由守卫，直到找到路由/login，然后放行
        if (sessionStorage.getItem("userInfo") == null) {
            utils.$$tools.info({message: "请先登录"});
            next('/login');
        } else {
            try {
                //如果已经登录过了，需要看当前是否刷新，刷新的时候，往Main下添加的子路由会消失，所以需要再次请求后台，构建路由信息
                if (router.options.routes[1].children.length == 0) {
                    await utils.$$api.loadUserMenus({}).then(res => {
                        store.dispatch("configEncryptData", res.encryptData);
                        store.dispatch("configChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);
                        let userMenus = JSON.parse(res.userMenus);//用户菜单对象
                        let routeMap=new Map();
                        //重新构造Main下的子路由信息
                        routObj.buildRoutes(userMenus,routeMap);
                        routObj.buildPortalsToRoute('',routeMap);
                        routeMap.has(to.path) ? next({}) : next('/404');
                    }).catch(err=>{
                        utils.$$tools.info({message: "很遗憾网络有点小问题"});
                        sessionStorage.removeItem(utils.$$const.baseVar.jfAccessToken);
                        sessionStorage.removeItem("userInfo");
                        location.reload();//由于userInfo被去除了。所以reload整个页面会在路由拦截那里经过判断，然后跳转到登录页
                    });
                }
            }catch (e) {
                next('/login');
            }
            if(to.fullPath!=store.getters.curRoute){//为了避免to:/路由A from:/路由A 这种无限循环，这里加入判断
                await store.dispatch("configCurRoute", to.fullPath);
                //把新构建的路由动态添加到路由表中
                userRoutes.forEach(item=>router.addRoute(item));
                //动态添加完路由之后，再次进入路由守卫，直到找到要去的路由，然后放行，放行是最后的next()
                next({...to, replace: true });
            }else{
                next()//放行出口
            }
        }
    }
})

export default router

<template>
    <div class="quotationPriceConfig" :style="{height:maxHeight+'px'}" v-loading.lock="fullscreenLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
        <el-row style="margin-bottom: 10px">
            <el-button type="primary" class="iconfont icon-baocun" key="save" @click="saveHandler" v-if="canEdit">保存</el-button>
            <el-button type="warning" class="iconfont icon-chongzhi" key="reset" @click="resetHandler">刷新</el-button>
        </el-row>
        <el-row  v-if="tableObj.fromArea">
            <el-col :span="1">装货点</el-col>
            <el-col :span="23">{{tableObj.fromArea}}</el-col>
        </el-row>
<!--如果车型和卸货点都没有配置，那么就不用显示起步价的设置了，因为没有车型和卸货点，那么就没有输入价格设置的地方-->
        <el-collapse v-model="activeNames">
            <el-collapse-item title="起步价" name="1" v-if="tableObj.cxList.length!=0 || tableObj.startPrice.columns.toPlaceList.length!=0">
                <el-table :data="tableObj.startPrice.tbData" style="width: 100%" :row-class-name="rowClassName" :ref="el=>refMap.set('startPrice',el)">
                    <!--左边的列，有起步价设置的里程范围、件数、重量、体积、配送点当中一个或者几个-->
                    <el-table-column :prop="item.prop" :label="item.label" v-for="item in tableObj.startPrice.columns.leftColumns"/>
                    <!--    如果车型和卸货点同时配置了的，那么就要有交叉组合了  -->
                    <el-table-column :label="cxItem.label" v-for="cxItem in tableObj.cxList" v-if="tableObj.cxList.length!=0 && tableObj.startPrice.columns.toPlaceList.length!=0">
                        <el-table-column :prop="cxItem.prop+'_'+toPlaceItem.prop" :label="toPlaceItem.label" v-for="toPlaceItem in tableObj.startPrice.columns.toPlaceList" width="120">
                            <template #default="scope">
                                <el-input-number :disabled="!canEdit" v-model="scope.row[cxItem.prop+'_'+toPlaceItem.prop]"
                                                 :controls="false" placeholder="请输入起步价格" precision="2" :max="9999999" style="width: 100%"/>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <!--    如果车型没有配置，则只显示卸货点        -->
                    <el-table-column :prop="item.prop" :label="item.label" v-for="item in tableObj.startPrice.columns.toPlaceList" v-else-if="tableObj.cxList.length==0">
                        <template #default="scope">
                            <el-input-number :disabled="!canEdit" v-model="scope.row[item.prop]" :controls="false" placeholder="请输入起步价格" precision="2" :max="9999999" style="width: 100%"/>
                        </template>
                    </el-table-column>
                    <!--    如果卸货点没有配置，则只显示车型        -->
                    <el-table-column :prop="item.prop" :label="item.label" v-for="item in tableObj.cxList" v-else-if="tableObj.startPrice.columns.toPlaceList.length==0">
                        <template #default="scope">
                            <el-input-number :disabled="!canEdit" v-model="scope.row[item.prop]" :controls="false" placeholder="请输入起步价格" precision="2" :max="9999999" style="width: 100%"/>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
            <el-collapse-item title="续费" name="2" v-if="tableObj.reNew.reNewItems.length!=0">
                <el-tabs>
                    <el-tab-pane :label="item.label" v-for="item in tableObj.reNew.reNewItems">

                        <el-table :data="item.tbData" :ref="el=>refMap.set(item.id,el)" v-if="tableObj.cxList.length>0">
                            <el-table-column :label="cxItem.label" v-for="cxItem in tableObj.cxList">
                                <el-table-column :prop="item.id+'_'+cxItem.prop+'_range'" label="范围"/>
                                <el-table-column :prop="item.id+'_'+cxItem.prop+'_price'" label="单位价格">
                                    <template #default="scope">
                                        <el-input-number :disabled="!canEdit" v-model="scope.row[item.id+'_'+cxItem.prop+'_price']"
                                                         :controls="false"  placeholder="请输入单位价格" precision="2" :max="9999999" style="width: 100%"/>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                        <!--上面是配置了车型的时候，续费按照车型和续费里程展开，如果没有配置车型，是否可以直接按照续费里程展开-->
                        <el-table :data="item.tbData" :ref="el=>refMap.set(item.id,el)" v-else>
                            <el-table-column :prop="item.id+'_range'" label="范围"/>
                            <el-table-column :prop="item.id+'_price'" label="单位价格">
                                <template #default="scope">
                                    <el-input-number :disabled="!canEdit" v-model="scope.row[item.id+'_price']"
                                                     :controls="false"  placeholder="请输入单位价格" precision="2" :max="9999999" style="width: 100%"/>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import {ref,reactive,toRefs, computed,defineComponent,onMounted,getCurrentInstance,nextTick} from 'vue';
    export default defineComponent ({
        name: "QuotationPriceConfig",
        props: {
            //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                fullscreenLoading:true,
                canEdit:true,
                activeNames:['1','2'],
                refMap:new Map(),
                clickTable:'',
                clickCol:'',
                clickRowIndex:0,
                maxHeight:600,
                // tableObj:{
                //     fromArea:'',
                //     cxList:[{prop:'jbei',label:'金杯'},{prop:'mb',label:'面包'}],
                //     startPrice:{
                //         columns:{
                //             leftColumns:[{prop:'startMileage',label:'里程'},{prop:'num',label:'件数'}],
                //             toPlaceList:[{prop:'jb',label:'江北'},{prop:'yb',label:'渝北'}]
                //         },
                //         tbData:[{ startMileage:'10-20',num:20,jbei_jb:1,jbei_yb:2,mb_jb:11,mb_yb:22}]
                //     },
                //     reNew:{
                //         reNewItems:[
                //                 {label:'里程',id:'renewMileage',tbData:[{range:'20-30',price:10.25},{range:'30-∞',price:22}]},
                //                 {label:'件数',id:'num',tbData:[]},
                //                 {label:'重量',id:'weight',tbData:[]},
                //                 {label:'体积',id:'size',tbData:[]},
                //                 {label:'配送点数',id:'pointNum',tbData:[]}
                //             ]
                //     }
                // },
                tableObj:{
                    fromArea:'',
                    cxList:[],
                    startPrice:{
                        columns:{
                            leftColumns:[],
                            toPlaceList:[]
                        },
                        tbData:[]
                    },
                    reNew:{
                        reNewItems:[]
                    }
                },
            })
            //利用表格的行样式设置回调方法给表格每行的值对象加上属性rowIndex
            const rowClassName=({row, rowIndex})=>{
                row.rowIndex = rowIndex;
            }
            onMounted(async ()=>{
                dataObj.maxHeight=(window.innerHeight-100);
                // console.log(props.engineParams.canEdit);
                dataObj.canEdit=props.engineParams.canEdit;

                nextTick(async()=>{
                    await loadData();
                    dataObj.fullscreenLoading=false;
                })
            })
            const loadData=async ()=>{
                let params = {operateType:'quotationPriceConfig',id:props.engineParams.id};
                let url = props.engineParams.reqPathPre + "/custom";
                let res = await utils.$$api.postRequest({ url: url, params:params});
                if(res.result){
                    let tableObj=JSON.parse(res.tableObj);
                    if(tableObj.cxList)dataObj.tableObj.cxList=tableObj.cxList;
                    if(tableObj.leftColumns)dataObj.tableObj.startPrice.columns.leftColumns=tableObj.leftColumns;
                    if(tableObj.toPlaceList)dataObj.tableObj.startPrice.columns.toPlaceList=tableObj.toPlaceList;
                    if(tableObj.leftTbData)dataObj.tableObj.startPrice.tbData=tableObj.leftTbData;

                    if(tableObj.reNew)dataObj.tableObj.reNew=tableObj.reNew;
                    if(tableObj.fromArea)dataObj.tableObj.fromArea=tableObj.fromArea;
                    if(res.isUsedBySaleBill)dataObj.canEdit=false;
                    // console.log(tableObj)
                }
            }

            const validateSaveItem=(item)=>{
                for(let key of Object.keys(item)){
                    // if('rowIndex'!=key && (''==item[key] || undefined==item[key])){//0==‘’居然为true，所以把rowIndex排除,因为他可能为0；如果填了值之后再手动删掉，那项会变为undefined，默认为''
                    if('rowIndex'!=key && (undefined==item[key])){//0==‘’居然为true，所以把rowIndex排除,因为他可能为0；如果填了值之后再手动删掉，那项会变为undefined，默认为''
                        utils.$$tools.warning({ message: '请补充完整所有价格才能保存'});
                        return false;
                    }
                }
                return true;
            }
            const saveHandler=async ()=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                resetEdit();
                let tbData={
                    startPriceData:[],
                    reNewData:{}
                }
                if(dataObj.refMap.has('startPrice')){
                    tbData.startPriceData=dataObj.refMap.get('startPrice').data;
                    for(let i=0;i<tbData.startPriceData.length;i++){
                        let item=tbData.startPriceData[i];
                        if(!validateSaveItem(item)){loading.close();return false;}
                    }
                }
                for(let [key,value] of dataObj.refMap){
                    if(key!='startPrice'){
                        tbData.reNewData[key]=value.data;
                        for(let rowData of Object.values(value.data)){
                            if(!validateSaveItem(rowData)){loading.close();return false;}
                        }
                    }
                }
                let params = {operateType:'saveQuotationPriceConfig',tbData:tbData,quotationId:props.engineParams.id};
                let url = props.engineParams.reqPathPre + "/custom";
                let res = await utils.$$api.postRequest({ url: url, params:params});
                props.engineParams.ownerComp.engine.doAddOrLoad(props.engineParams.id)
                loading.close();
                if(res.result)utils.$$tools.success();
            }
            const resetHandler=async ()=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                resetEdit();
                await loadData();
                loading.close();
                utils.$$tools.success();
            }
            const resetEdit=()=>{
                dataObj.clickCol='';
                dataObj.clickRowIndex=0;
            }
            return{
                ...toRefs(dataObj),rowClassName,saveHandler,resetHandler
            }
        }
    });

</script>

<style scoped>
.quotationPriceConfig{
    overflow: auto;
    height: 500px;
}
</style>
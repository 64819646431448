<template>
    <div class="verify">
        <div class="test"></div>
<!--        <el-form ref="formRef" :model="form" :rules="computeRules(form.result)" label-width="100px">-->
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="审核结果" prop="result">
                        <el-radio-group v-model="form.result" @change="radioChange">
                            <el-radio :label="0">通过</el-radio>
                            <el-radio :label="1">拒绝</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
<!--                    <el-form-item label="审核备注" prop="remark" :rules="{required: form.result==1, message: '请输入审核备注', trigger: 'blur'}">-->
                    <el-form-item label="审核备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    import {ref,reactive,toRefs, computed,defineComponent,onMounted,getCurrentInstance} from 'vue';
    export default defineComponent ({
        name: "Verify",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            const formRef=ref(null);
            let dataObj=reactive({
                form:{
                    result:0,
                    remark:''
                },
                //表单验证规则
                rules: {
                    result: [
                        {required: true, message: "请选择审核结果", trigger: "blur" }
                    ]
                },
                rules1: {
                    result: [
                        {required: true, message: "请选择审核结果", trigger: "blur" }
                    ],
                    remark: [
                        {required: true, message: "请输入审核备注", trigger: "blur" }
                    ]
                }
            })
            onMounted(async ()=>{

            })
            //---------------------------computed---------------------------

            //下拉select数据集获取（数据集构造和cascader类似）
            const computeRules=computed(()=>{
                return (params) => {
                    if(dataObj.form.result==0){
                        return dataObj.rules;
                    }else if(dataObj.form.result==1){
                        return dataObj.rules1;
                    }
                }
            })
            const radioChange=(label)=>{
                // formRef.value.resetFields();//重置表单并且清除验证
                formRef.value.clearValidate('remark', emailError => {//清除remark的验证
                    if (!emailError) {
                        console.log('验证通过!');
                    } else {
                        console.log('验证失败');
                        return false;
                    }
                });

                if(label==0){
                    if(dataObj.rules.remark)delete dataObj.rules.remark;
                }else if(label==1){
                    dataObj.rules.remark=[ {required: true, message: "请输入审核备注", trigger: "blur" }];
                    formRef.value.validateField('remark', emailError => {//验证remark是否合法
                        if (!emailError) {
                            console.log('验证通过!');
                        } else {
                            console.log('验证失败');
                            return false;
                        }
                    });
                }
            }
            return{
                ...toRefs(dataObj),radioChange,formRef,computeRules
            }
        }
    });

</script>

<style scoped>
    .verify{

    }
    .test{
        width: 0px;
        height: 0px;
    }
</style>
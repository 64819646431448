import ElementPlus,{ElDatePicker,ElInput,ElTableColumn,ElCascader,ElSelect} from 'element-plus';
import '../element-variables.scss'
import locale from 'element-plus/lib/locale/lang/zh-cn'

//1.0.2-beta.59之后，必须自定义configProvider，否则组件内部默认是英文，比如el-pagination等等
//https://www.jianshu.com/p/9059539d4d9a
ElementPlus.useLang = (app, ref, locale) => {
    const template = (str, option) => {
        if (!str || !option) return str
        return str.replace(/\{(\w+)\}/g, (_, key) => {
            return option[key]
        })
    }

    // 注入全局属性,子组件都能通过inject获取
    app.provide('ElLocaleInjection', {
        lang: ref(locale.name),
        locale: ref(locale),
        t: (...args) => {
            const [path, option] = args
            let value
            const array = path.split('.')
            let current = locale
            for (let i = 0, j = array.length; i < j; i++) {
                const property = array[i]
                value = current[property]
                if (i === j - 1) return template(value, option)
                if (!value) return ''
                current = value
            }
        },
    })
}



//修改ElementPlus组件的默认属性
//日期范围默认属性修改
ElDatePicker.props.startPlaceholder = { type: String, default: '开始日期'};
ElDatePicker.props.endPlaceholder = { type: String, default: '结束日期'};
ElDatePicker.props.rangeSeparator = { type: String, default: '至'};
ElDatePicker.props.valueFormat = { type: String, default: 'YYYY-MM-DD'};//这个版本的elementui-plus不支持，新版本支持
ElDatePicker.props.style = { type: String, default: 'width:100%'};

// ElInput.props.clearable.default=true;
ElInput.props.showWordLimit.default=true;
//修改TableColumn：值对齐居中
ElTableColumn.props.align={type:String, default: "center"};
//值溢出的时候用...代替
ElTableColumn.props.showOverflowTooltip={type:Boolean, default: true};

ElSelect.props.filterable={type:Boolean, default: "true"};

//修改Cascader：默认可以清空、可以搜索、选中只展示最后一级
ElCascader.props.clearable={type:String, default: "true"};
ElCascader.props.filterable={type:String, default: "true"};
ElCascader.props.showAllLevels.default=false;
ElCascader.props.props={type:Object, default: ()=>{
    return{
        checkStrictly: true,emitPath:false
    }
}};
//如果ElCascader设置style：width=100%效果会出问题，但是ElDatePicker却没事，不知道为啥
// ElCascader.props.style = { type: String, default: 'width:100%'};

export default (app,ref) => {
    let elementUISize=localStorage.getItem("elementUISize");
    if(null==elementUISize || 'null'==elementUISize){
        elementUISize='small';//设置默认大小
    }
    ElementPlus.useLang(app, ref, locale)
    app.use(ElementPlus, { size: elementUISize, zIndex: 3000,locale })
}

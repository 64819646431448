import QuotationPriceConfig from "@/views/project/lghd/commons/QuotationPriceConfig";
import Verify from "@/views/project/lghd/commons/Verify";
import ChooseAddress from "@/views/project/lghd/commons/ChooseAddress";
import CustomerAddressList from "@/views/project/lghd/xsgl/customer/address/CustomerAddressList";
export const $$lghdUtils = {
    //处理请求结果，适配select
    dealSelectData:(inData)=>{
        let data=[];
        inData.forEach((item,index)=>{
            data.push({value:item.id,label:item.name,item:item});
        })
        return data;
    },
    buildCommonDialog:async(params)=>{
        let options=Object.assign({},{
            dWidth:'80%',
            dialogDiv:"dialogDiv",
            showFooterBtn:true,
            ownerComp:params.proxy
        },params);
        let vm = await options.proxy.utils.$$dialog.create(options);
        vm.dialogVisible = true;
    },
    //打开报价单,必传参数: id-报价单id proxy-调用该方法的模块实例
    buildQuotationPriceConfigDialog:async (params)=>{
        let options = Object.assign({}, {
            id:params.id,
            reqPathPre:'/customerQuotation',
            modelComp: QuotationPriceConfig,
            ownerComp:params.proxy,
            title:"报价单费用配置",
            dialogDiv:"QuotationPriceConfigDiv",
            contentHeight:200,
            canEdit:false,
            fullscreen:true,
            showFooterBtn:false
        }, params);

        let vm = await params.proxy.utils.$$dialog.create(options);
        vm.dialogVisible = true;
    },
    //停用/启用抽取
    changeStatus:(options)=>{
        let params=Object.assign({},options);
        params.proxy='';
        options.proxy.utils.$$tools.configBox({
            msgContent:'确定要执行该操作吗?',
            fn:async ()=> {
                let url = options.url;
                params= JSON.stringify(params);
                let res = await options.proxy.utils.$$api.postRequest({ url: url, params: params});
                options.proxy.utils.$$tools.success({ message: res.msg });
                if (options.proxy.engine.engineParams.ownerComp && options.proxy.engine.engineParams.ownerComp.queryHandler) options.proxy.engine.engineParams.ownerComp.queryHandler();
                await options.proxy.engine.doAddOrLoad(options.proxy.engine.id);
            }
        });
    },
    startHandler:async (options)=>{
        let params=Object.assign({},{type:'start',operateType:'changeStatus'},options);
        await $$lghdUtils.changeStatus(params);
    },
    stopHandler:async (options)=>{
        let params=Object.assign({},{type:'stop',operateType:'changeStatus'},options);
        await $$lghdUtils.changeStatus(params);
    },
    //从地图选择地址
    buildConfigAddressDialog:async (params)=>{
        let options=Object.assign({}, {
            modelComp: ChooseAddress,
            ownerComp:params.proxy,
            title:"选择地址",
            dialogDiv:"configAddressDiv",
            fullscreen:true,
            // contentHeight:400,
            showFooterBtn:true
        }, params);
        let vm = await params.proxy.utils.$$dialog.create(options);
        vm.dialogVisible = true;
    },
    //选择客户地址（可以添加）
    buildChooseAddressDialog:async (params)=>{
        let options=Object.assign({}, {
            modelComp: CustomerAddressList,
            ownerComp:params.proxy,
            title:"选择地址",
            dialogDiv:"chooseAddressDiv",
            // contentHeight:400,
            showFooterBtn:true
        }, params);
        let vm = await params.proxy.utils.$$dialog.create(options);
        vm.dialogVisible = true;
    },
    //审核
    buildClaimDialog:async (params)=>{
        let options=Object.assign({}, {
            modelComp: Verify,
            ownerComp:params.proxy,
            title:"审核",
            dialogDiv:"verifyDiv",
            contentHeight:200,
            showFooterBtn:true
        }, params);
        let vm = await params.proxy.utils.$$dialog.create(options);
        vm.dialogVisible = true;
    },
    claimDialogSure:async (options)=>{
        if(options.modelInst.form.result==1 && !options.modelInst.form.remark){
            options.proxy.$message('请输入拒绝原因');
            return false;
        }
        let params = Object.assign({},{operateType:'verify',id:options.id},options.modelInst.form);
        let res = await options.proxy.utils.$$api.postRequest({ url: options.url, params:params});
        if(res.result){
            await options.proxy.engine.doAddOrLoad(options.proxy.engine.id);
            if (options.proxy.engine.engineParams.ownerComp && options.proxy.engine.engineParams.ownerComp.queryHandler) options.proxy.engine.engineParams.ownerComp.queryHandler();
            options.proxy.utils.$$tools.success();
        }else{
            return false;
        }
        return true;
    },
    //上传--------------------------
    isPic:(file)=>{
        let type=file.substr(file.lastIndexOf('.')+1);
        type=type.toLocaleLowerCase();
        return type=='png' || type=='jpg' || type=='jpeg';
    },
    //加载上传组件的文件内容
    loadUploadFiles:(options)=>{
        if(options.files){
            const aloneServerInfo=options.proxy.utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
            options.dataObj.refMap.get(options.uploadType).fileList=[];//要先清空，否则重载的时候要重复
            options.files.forEach((item)=>{
                let path=item.path;
                if(item.saveType==0){
                    path=item.path.substr(item.path.indexOf('/otherFiles'));
                    path=aloneServerInfo+path;
                }
                options.dataObj.refMap.get(options.uploadType).fileList.push({name:item.preName,url:path,id:item.id});
                if($$lghdUtils.isPic(path))options.dataObj.refMap.get(options.uploadType).dialogImageUrls.push(path);
            })
            if(options.disabled)options.dataObj.refMap.get(options.uploadType).disableUpload(options.disabled);
        }
    },
    //把上传的文件追加到上传控件中
    dealAfterUpload:(options)=>{
        let uploadArr=[];
        options.res.data.uploadFiles.forEach((item,index)=>{
            uploadArr.push({name:item.preName,url:item.fileUrl,id:item.id});
            options.uploadInst.dialogImageUrls.push(item.fileUrl);//设置点击查看上传图片详情的时候，图片组件可以显示的图片地址集合
        })
        options.uploadInst.fileList=options.uploadInst.fileList.concat(uploadArr);
    }
}
const ADD_TABSVIEW = 'ADD_TABSVIEW';//增加一个新的tab
const CLOSE_TABSVIEW = 'CLOSE_TABSVIEW';//清除指定的tab
const CLOSE_ALL_TABSVIEW = 'CLOSE_ALL_TABSVIEW';//清除所有tabs，只保留welcome
const CLOSE_OTHER_TABSVIEW = 'CLOSE_OTHER_TABSVIEW';//除了当前tab和welcome之前，清除其它所有tabs
const CHECK_TABSVIEW_EXIST = 'CHECK_TABSVIEW_EXIST';//检查指定tab是否已经打开
const tabsview = {
    state: {
        visitedTabsView: []//目前已有的tabs
    },
    mutations: {
        [ADD_TABSVIEW](state, view) {
            if (state.visitedTabsView.find((n) => n.path === view.path))return;//如果要添加的tab已经打开添加过了，则直接返回
            state.visitedTabsView.push({ title: view.meta.title, path: view.path });//添加tab到数组中
        },
        [CLOSE_TABSVIEW](state, view) {
            for (let [i, v] of state.visitedTabsView.entries()) {//循环已打开的tabs，找到要清除的tab，把它从已打开的tabs中清除掉
                if (v.path === view.path || v.title === view.title) {
                    state.visitedTabsView.splice(i, 1);break;
                }
            }
        },
        [CLOSE_ALL_TABSVIEW](state) {//清除所有的tab，然后添加welcome，相当于只保留welcome
            state.visitedTabsView.length=0;
            state.visitedTabsView.push({ title: "首页", path: "/welcome" })
        },
        [CLOSE_OTHER_TABSVIEW](state,view) {
            state.visitedTabsView.length=0;//先清除所有的tabs，然后添加当前的tab到已访问的tabs数组中，如果当前tab不是welcome，还要把welcome也添加到已访问的tabs数组中
            if(view.path!='/welcome')state.visitedTabsView.push({ title: "首页", path: "/welcome" })
            state.visitedTabsView.push({ title: view.title, path: view.path });
        },
        [CHECK_TABSVIEW_EXIST](state,view) {
            return state.visitedTabsView.some((n) => n.path === view.path);
        }
    },
    actions: {
        // 添加一个新的tabsView
        addVisitedTabsView({ commit }, view) {//当前的路由不是/则添加
            if(view.path!='/')commit(ADD_TABSVIEW, view)
        },
        // 关闭一个tabsView
        closeSpeTabsView({ commit, state }, view) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    commit(CLOSE_TABSVIEW, view)
                    resolve([...state.visitedTabsView])//先展开，然后再加上[]，相当于复制一下visitedTabsView
                }
            })
        },
        //清除所有已经访问的tabs
        closeAllVisitedTabsView({ commit }) {
            commit(CLOSE_ALL_TABSVIEW)
        },
        //关闭其它tabs
        closeOtherTabsView({ commit }, view) {
            commit(CLOSE_OTHER_TABSVIEW, view)
        },
        //检查指定路由是否在已显示的路由数组中
        checkTabsViewExist({ commit,state }, view) {
            // commit(CHECK_TABSVIEW_EXIST, view)
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    let exist=state.visitedTabsView.some((n) => n.path === view.path);
                    resolve(exist);
                }
            })
        }
    },
    getters: {
        //获取现在已有的tabs
        visitedTabsView: state => state.visitedTabsView
    }
}

export default tabsview
import AMapLoader from "@amap/amap-jsapi-loader";
import { ElLoading } from 'element-plus'
let mapUtils= {
    buildMapInst:(options)=>{
        const loading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0, 0, 0, 0.7)'});
        return new Promise((resolve, reject) => {
            AMapLoader.load({
                key:'e649ecd0052d9edd4312ed435e0e12d1',  //设置您的key
                version:"2.0",
                plugins:['AMap.ToolBar','AMap.Driving','AMap.Geocoder'],
                AMapUI:{
                    version:"1.1",
                    plugins:[],
                },
                Loca:{
                    version:"2.0.0"
                },
            }).then(async (AMap)=>{
                let center=[106.517219,29.610101];
                if(options.center)center=options.center;
                let searchAutoComplete={},geocoder={};
                let mapInst = new AMap.Map("container",{
                    viewMode:"3D",
                    // zoom:5,
                    zoom:16,//级别
                    zooms:[2,22],
                    center:center,
                });
                AMap.plugin('AMap.AutoComplete', function(){
                    let autoOptions = {
                        city: '全国'//city 限定城市，默认全国
                    };
                    // 实例化AutoComplete
                    searchAutoComplete= new AMap.AutoComplete(autoOptions);
                })
                geocoder = new AMap.Geocoder({
                    city: "全国", // radius: 1000 //范围，默认：500
                });

                let defaultMarkerPoints=[
                    {lng:106.517219,lat:29.610101,title:'速享科技总部大厦',AMap:AMap,mapInst:mapInst}
                ]
                let markerPoints=[];
                if(options.markerPoints){
                    options.markerPoints.forEach(item=>{
                        let pointInfo=Object.assign({AMap:AMap,mapInst:mapInst},item);
                        markerPoints.push(pointInfo);
                    })
                }else{
                    markerPoints=defaultMarkerPoints;
                }
                for(let item of markerPoints){
                    if(item.lng && item.lat)mapUtils.buildMarker(item);
                }
                loading.close();
                resolve({AMap:AMap,mapInst:mapInst,searchAutoComplete:searchAutoComplete,geocoder:geocoder});
            }).catch(e=>{
                console.log(e);
                loading.close();
            })
        })
    },
    //params  {AMap:AMap,mapInst:mapInst,lng:111,lat:222}
    buildMarker:(options)=>{
        let marker = new options.AMap.Marker({
            position:[options.lng,options.lat],//位置
            title: options.title?options.title:''
        })
        try {
            options.mapInst.add(marker);//添加到地图
        }catch (e) {}
        return marker;
    },
    //params  {lngLat:[{lng:11,lat:222}],geocoder:geocoder}
    getAddressByLngLat:(options)=>{
        options.geocoder.getAddress(options.lngLat, function(status, result) {
            if (status === 'complete' && result.regeocode) {
                // console.log(result)province  city  district  street streetNumber
                let address = result.regeocode.formattedAddress;
                let province=result.regeocode.addressComponent.province;
                let district=result.regeocode.addressComponent.district;
                if(options.fn)options.fn({address:address,province:province,district:district})
            }else{
                log.error('根据经纬度查询地址失败')
            }
        });
    }
}
export default mapUtils;
import Qs from 'qs';
import axios from "axios";
import utils from './jh.core.js';
const config = require('./config.js');
//创建axios实例
const axiosInst = axios.create({
    timeout: 500000
});
//不能把baseURL放到axios实例身上，因为发请求的时候，会根据业务需求去构建url
// const baseURL='http://127.0.0.1:7788';
// const baseURL='http://192.168.7.131:8010';
//前端请求拦截器（发送请求之前）。
//往即将发送请求的header中放入一些参数，包括encryptData、userToken,后台收到请求之后，就可以从header中取出这些参数。
axiosInst.interceptors.request.use(
    reqConfig => {
        //数据是否加密传输，每次请求都带上放到header中，方便在controller接收和返回处理中决定是否解密和加密
        reqConfig.headers["encryptData"] = utils.$$tools.getPropFromVuex('encryptData');
        //每次发请求都往header中放入token，后台会取出token进行验证。包括是否传入token，token是否合法、是否过期等。
        const userToken = sessionStorage.getItem(utils.$$const.baseVar.jfAccessToken);
        //要判断sessionStorage中是否有token，有可能还未登录，只是进入登录页面发送的后台请求，那么就还没有token,
        //只有登录成功之后，后台才会把token返回给前台，然后前台才把token放入sessionStorage中
        if(userToken){
            reqConfig.headers[utils.$$const.baseVar.jfAccessToken] = userToken;
        }
        return reqConfig;//必须返回reqConfig，否则会报错：Cannot read property 'cancelToken' of undefined
    },
    error => {
        return Promise.error(error);
    }
);
//响应拦截器（请求响应之后）
//后端往前端返回的内容中，会把一些参数写入header中，比如本次请求的状态码errorCode、Jf-Access-Token（token可能过期更新了）
//注意：从header中取参数的时候必须全部小写。响应懒截器执行完之后，才会执行请求结果promise
axiosInst.interceptors.response.use(
    res => {
        const invalidCode=[utils.$$const.sysCode._0001,utils.$$const.sysCode._0002,utils.$$const.sysCode._0009,utils.$$const.sysCode._0010
            ,utils.$$const.sysCode._0014,utils.$$const.sysCode._0015]
        //如果本次请求状态为成功，且响应结果header中包含有Jf-Access-Token，那么把header中的Jf-Access-Token取出更新sessionStorage中的f-Access-Token
        if(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()]==utils.$$const.sysCode._0000 && res.headers[utils.$$const.baseVar.jfAccessToken.toLowerCase()]){
            sessionStorage.setItem(utils.$$const.baseVar.jfAccessToken, res.headers[utils.$$const.baseVar.jfAccessToken.toLowerCase()]);
        }else if(invalidCode.includes(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()])){//token验证失败token(0001)没有传入(0002)token过期(0009)非法访问（0010），跳转到登录页
            sessionStorage.removeItem(utils.$$const.baseVar.jfAccessToken);
            sessionStorage.removeItem("userInfo");
            location.reload();//由于userInfo被去除了。所以reload整个页面会在路由拦截那里经过判断，然后跳转到登录页
        }
        //响应成功，继续交给后续处理。必须返回，否则我们的请求不能得到响应结果
        return Promise.resolve(res);
    },
    err => {
        console.log(err);
        return Promise.reject(err);
    }
);
//发送post请求
export const $$post = function (options) {
    return new Promise((resolve, reject) => {
        let url=options.url;
        if(options.urlPre){//如果指定了请求服务器前缀地址，则请求发往指定的服务器（比如附件的删除、图片资源的删除就会指定urlPre）
            url=options.urlPre + options.url;
        }else{//如果没有指定了请求上下文，则看系统是否配置过上下文，配置了则要把系统配置的上下文给加上
            if(config.contextPath)url=config.contextPath +url;
            url=config.baseURL+url;
        }
        let params = JSON.stringify(options.params);
        if(utils.$$tools.getPropFromVuex('encryptData'))params = utils.$$str.encrypt(params);//如果要加密传输，需要把参数进行加密处理
        axiosInst({
            method: "post",
            url: url,
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'//用form的方式传递参数给后台
                // 'Content-Type':'application/json'//用form的方式传递参数给后台(目前不支持)
            },
            transformRequest:[
                // `transformRequest` 允许在向服务器发送前，修改请求数据
                // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法。在这里没有加入新的请求数据
                function (data) {
                    return Qs.stringify(data)
                }
            ],
            data: { data: params}//发往后端的参数名称叫做data，值是一个json字符串
        }).then(res => {
            if(res.data){
                //如果进行过加密处理，那么返回回来之后需要进行对结果解密
                if(utils.$$tools.getPropFromVuex('encryptData'))res.data=JSON.parse(utils.$$str.decrypt(res.data));
                if(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()]==utils.$$const.sysCode._0000){//必须是拦截器返回成功才处理其它
                    if (!res.data.result) {
                        utils.$$tools.error({ message: res.data.msg });
                    }
                    resolve(res.data);
                }
            }
        }).catch(err => {
            utils.$$tools.error({ message: err });
            reject(err)
        });
    });
};
//文件上传
export const $$upload = function (options) {
    return new Promise((resolve, reject) => {
        let url=options.url;
        if(!url.startsWith("/"))url="/"+url;
        if(options.urlPre){//如果指定了请求服务器前缀地址，则请求发往指定的服务器（比如附件的删除、图片资源的删除就会指定urlPre）
            url=options.urlPre + options.url;
        }else{//如果没有指定了请求上下文，则看系统是否配置过上下文，配置了则要把系统配置的上下文给加上
            if(config.contextPath)url=config.contextPath +url;
            url=config.baseURL+url;
        }
        axiosInst.post(url,options.formData).then(async (res)=> {//formData包含了上传的文件信息
            if(utils.$$tools.getPropFromVuex('encryptData')){
                res.data=JSON.parse(utils.$$str.decrypt(res.data));
            }
            resolve(res.data);
        }).catch(err => {
            utils.$$tools.error({ message: err });
        });
    });

}
//文件下载。
//系统上传的附件默认是放到一台服务器管理的，所以这里下载必须指定那台服务器的信息，服务器信息在登录成功之后，由后台返回给前台，前台把它放到sessionStorage中的。
export const $$downLoad = function (options) {
    return new Promise((resolve, reject) => {
        //下面从sessionStorage中取值放到最顶部还不行，不知道为什么
        const aloneServerInfo=utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
        let url= aloneServerInfo+ options.url;//默认文件下载是在独立服务器上处理的
        //如果传入了cluserServer而且cluserServer=1表示不在独立服务器上处理，在本机处理下载的业务（比如机构、部门等等导入，模版附件在每台服务器上都有）
        if(options.cluserServer && options.cluserServer==1){
            if(config.contextPath)url=config.contextPath+options.url;
            url=config.baseURL+url;
        }
        let params = JSON.stringify(options.params);
        if(utils.$$tools.getPropFromVuex('encryptData'))params = utils.$$str.encrypt(params);//如果要加密传输，需要把参数进行加密处理
        axiosInst({
            url: url,
            method: 'post',
            responseType: 'blob',     //接收类型设置，否者返回字符型
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'//用form的方式传递参数给后台
            },
            transformRequest:[
                function (data) {
                    return Qs.stringify(data)
                }
            ],
            data: { data: params}//发往后端的参数名称叫做data，值是一个json字符串
        }).then(res => {//注意：下载附件后台返回的状态码key叫做downloadcode
            if(utils.$$const.sysCode._0000==res.headers[utils.$$const.baseVar.downloadcode.toLowerCase()]){
                const blob = res.data
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = (e) => {
                    const a = document.createElement('a');
                    a.download = options.params.fileName;
                    a.href = e.target.result;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }else{
                utils.$$tools.error({message:'附件丢失了'});
            }
            resolve(res);
        }).catch(err => {
            utils.$$tools.error({message:err});
        });
    });
};




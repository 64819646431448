<template>
    <div class="listEngineOuterDiv" id="listEngineOuterDiv">
        <div class="listEngine" v-loading.lock="fullscreenLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
            <div :style="{height: btnGroupHeight+'px'}" class="btnGroup" v-if="listParam.isShowBtnGroup">
                <slot name="btnGroup">
                    <template v-for="btn in listButtons">
                        <el-button v-if='btn.type' :type="btn.type" :class="btn.icon"
                                   @click="clickHandler(btn.clickEvent)" :key="btn.id" :disabled="btn.disabled">
                            {{btn.title}}
                        </el-button>
                        <el-button v-else :class="btn.icon" @click="clickHandler(btn.clickEvent)" :key="btn.id"
                                   :disabled="btn.disabled">{{btn.title}}
                        </el-button>
                    </template>
                </slot>
            </div>

            <div :style="{height:queryHeight+'px'}" v-if="listParam.isShowQueryParam">
                <el-collapse @change="handleChange">
                    <el-collapse-item title="查询条件" name="queryCondition">
                        <slot name="queryParams"/>
                    </el-collapse-item>
                </el-collapse>
            </div>

            <div class="gridinfo">
                <GridTable ref="tbRef" v-bind="listParam">
                    <!--替换GridTable插槽-->
                    <template v-slot:_tbCols>
                        <slot name="fixCol" v-if="listParam.isShowFixCol">
                            <el-table-column fixed="left" header-align="center" align="center" label="操作" width="140"
                                             v-if="(backData.canDel || backData.canEdit || backData.canView)">
                                <template #default="scope">
                                    <div class="editDel">
                                        <el-button @click="editHandler(scope.row)" type="text" v-if="isShowEdit(scope.row)">
                                            <div style="color:#409eff"><i class="el-icon-edit"/>&nbsp;&nbsp;详情</div>
                                        </el-button>
                                        <el-button @click="deleteHandler(scope.row)" type="text" v-if="isShowDel(scope.row)">
                                            <div style="color:red"><i class="el-icon-delete"/>&nbsp;&nbsp;删除</div>
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </slot>
                        <slot name="tbCols"/>
                    </template>
                </GridTable>
            </div>

            <el-dialog title="导出设置" v-model="exportExcelVisible" :append-to-body="true">
                <el-radio-group v-model="exportType">
                    <el-radio :label="0">导出当前页</el-radio>
                    <el-radio :label="1">导出所有页</el-radio>
                </el-radio-group>
                <template #footer>
                    <el-button class="iconfont icon-shanchu" @click="exportExcelVisible=false">取 消</el-button>
                    <el-button type="primary" class="iconfont icon-queding" @click="sureExport()">确 定</el-button>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import listEngine from "./listEngineHelper.js";

    export default listEngine;
</script>

<style scoped>
    /*为了给listEngine增加两道白色的光,采用了伪元素，所以需要增加一个div采用相对定位，把listEngine采用绝对定位*/
    .listEngineOuterDiv{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }
    .listEngine {
        position: absolute;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        /*justify-content: space-between;*/
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }


    .btnGroup {
        display: flex;
        align-items: center;
    }

    .gridinfo {
        flex: 1;
        /* height: calc(100% - 82px); 82=41（顶部tool按钮）+41(查询折叠) */
    }

    .listEngine ::v-deep(.el-collapse-item__content){
        padding-bottom: 0px !important;
    }

    .listEngine ::v-deep(.el-collapse-item__header){
        border-bottom: 0px;
        height: 30px;
        line-height: 30px;
    }

    .editDel {
        display: flex;
        justify-content: center;
    }

    /*给el-collapse加上边框和圆角*/
    .listEngine ::v-deep(.el-collapse-item__header){
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: #FAFAFA;
    }
    .listEngine ::v-deep(.el-collapse){
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-left: solid 1px #EBEEF5;
        border-right: solid 1px #EBEEF5;

    }
    .listEngine ::v-deep(.el-collapse-item__wrap){
        background: #FAFAFA;
    }
</style>

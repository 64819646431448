export const $$str = {
    /*字符串加密*/
    encrypt: function (txt) {
        if (!txt) return txt;
        let rt = [];
        let i, s, l = txt.length;
        s = (l + 10).toString(36);
        rt.push((s.length + 10).toString(21) + s);
        for (i = 0; i < l; i++) {
            s = (txt.charCodeAt(i) + (i + 1) * 10 + l).toString(36);
            rt.push((s.length + 10).toString(21) + s);
        }
        return rt.join('');
    },
    /*字符串解密*/
    decrypt: function (txt) {
        if(!txt)return ;
        let rt = [];
        let i, l, l1, s, n = 1, srclen = 0;
        for (i = 0, l = txt.length; i < l;) {
            l1 = parseInt(txt.charAt(i), 21) - 10;
            s = txt.substr(++i, l1);
            if (i > 1) {
                rt.push(String.fromCharCode(parseInt(s, 36) - n * 10 - srclen));
                n++;
            } else {
                srclen = parseInt(s, 36) - 10;
            }
            i += l1;
        }
        return rt.join('');
    },
    isNotEmpty: function (strInput) {
        return !(strInput == null || (strInput + "") == "" || !strInput);
    },
    isEmpty: function (strInput) {
        return strInput == null || (strInput + "") == "" || !strInput;
    },
    isWangEditEmpty:function(str){
        let num = 0,reg = /<p>(&nbsp;|&nbsp;\s+)+<\/p>|<p>(<br\/>)+<\/p>/g;
        while (num < str.length && str != "")
        {
            num++;
            let k = str.match(reg);
            if (k) {
                str = str.replace(k[0], "");
            }
        }
        return str == "";
    },
    isObjectEqual(a,b){
        let aProps = Object.getOwnPropertyNames(a);//拿到两个对象的所有key
        let bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {//看长度是否相等
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            if(propName=='__ob__')continue;//不去掉这个会有死循环
            let propA = a[propName];
            let propB = b[propName];
            if ((typeof (propA) === 'object')) {
                let result=this.isObjectEqual(propA, propB);
                //为true的时候不能return true,因为后面的对象可能还没判断，只是前面的对象相等，后面可能不等呢，所以只返回不相等的情况
                if(!result)return false;
            } else if (propA !== propB) {
                return false
            }
        }
        return true
    },
    //可以delete
    formatDate:function(date,join){
        if(!date)return '';
        let d = new Date(date),month = '' + (d.getMonth() + 1),day = '' + d.getDate(),year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if(!join)join='-';
        return [year, month, day].join(join);
    },
    //可以delete
    formatDateRange:function(dateRange){
        if(!dateRange)return '';
        return this.formatDate(dateRange[0])+'至'+this.formatDate(dateRange[1])
    },
    //从数组中删除指定项
    delItemFromArr:(arr,delItem)=>{
        let index=arr.findIndex((item) => $$str.isObjectEqual(item,delItem));
        if(index!=-1){
            arr.splice(index,1);
        }
    },
    randomString:(length)=>{
        var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (let i = length; i > 0; --i)
            result += str[Math.floor(Math.random() * str.length)];
        return result;
    },
    guid:()=>{
        return  'xxxxxxxx-xxxx' .replace(/[xy]/g, (c)=>{
            let  r = Math.random()*16|0, v = c ==  'x'  ? r : (r&0x3|0x8);
            return  v.toString(16);
        });
    },
    phoneValidate:(value)=>{
        const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
        const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/
        return (reg1.test(value) || reg2.test(value));
    },
    //手机和座机
    checkPhone:(rule, value, callback,required)=> {
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
            const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/

            console.log(value,reg1.test(value))
            if (reg1.test(value) || reg2.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    },
    //{mergeColInfo:[{began:1,end:3,flag=false},{began:4,end:6,flag=true},{began:7,end:9,flag=false},{began:10,end:12,flag=true}]}
    merges:(options)=>{
        let arr1=['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
        let resultArr=[];
        for(let i=0;i<options.mergeColInfo.length;i++){
            let mergeColItem=options.mergeColInfo[i];
            if(mergeColItem.flag){
                let item=arr1[mergeColItem.began-1]+"1:"+arr1[mergeColItem.end-1]+"1";
                resultArr.push(item);
            }else{
                for(let j=mergeColItem.began;j<=mergeColItem.end;j++){
                    let item=arr1[j-1]+"1:"+arr1[j-1]+"2";
                    resultArr.push(item);
                }
            }
        }
        console.log(resultArr)
    },
    //手机
    checkMobilePhone:(rule, value, callback,required)=> {
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
            if (reg.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    }
};


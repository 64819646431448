//表格请求后台完成之后事件
const AFTERPAGEDATA = "afterPageData";
//表格请求后台完成之后事件
const GRIDLOADED = "gridLoaded";
//删除之前的事件
const BEFOREDELETEHANDLER = "beforeDeleteHandler";
//删除之后的事件
const AFTERDELETEHANDLER = "afterDeleteHandler";
//删除
const DELETEHANDLER = "deleteHandler";
//编辑
const EDITHANDLER = "editHandler";
//新增
const ADDHANDLER = "addHandler";
//查询
const QUERYHANDLER = "queryHandler";
//dialog弹出框确认方法回调
const SUREHANDLER = "sureHandler";
//dialog弹出框取消方法回调
const CANCELHANDLER = "cancelHandler";
//导出excel
const EXPORTEXCEL = "exportExcel";
//是否显示某行前面的删除链接 参数：row
const ISSHOWDEL = "isShowDel";
//是否显示某行前面的编辑链接 参数：row
const ISSHOWEDIT = "isShowEdit";
import {useRoute} from "vue-router";
import { ref,reactive,toRefs,onMounted,computed,getCurrentInstance,provide,nextTick,defineComponent,inject} from 'vue'
const listEngine = defineComponent({
    name: "ListEngine",
    props:{
        pageList: {//查询条件
            type: Object,
            default:()=>{return{}}
        }
    },
    //使用了gridTable控件，必须提供一下gridTableOwner，这里把listEngine的父类提供出去，
    //那么就可以在gridTable控件调用listEngine的父类的方法了
    setup(props,context){
        const route = useRoute();
        const appInstant = getCurrentInstance();
        let {proxy}=getCurrentInstance();
        let parentInst=appInstant.parent.proxy;
        const utils=proxy.utils;
        let pageParams=props.pageList;
        const tbRef=ref(null);
        provide('gridTableOwner', appInstant.parent.proxy);
        let mainComp = inject('mainComp');
        let dataObj=reactive({
            mainComp:mainComp,
            fullscreenLoading:true,//进入ListEngine的时候显示遮罩层，这个是局部遮罩，如果用指令方式就是整个页面都会遮罩
            exportExcelVisible: false,//导出弹出框的控制/影藏控制字段
            backData: {},//listEngine查询后台之后返回的结果

            queryConditionHeight: 0,//查询条件的高度，默认是0，如果要显示查询条件，需要在显示之前改变这个高度
            queryHeight:31,//查询条件折叠面板标题的高度，在显示之前，会重新计算该值
            btnGroupHeight:0,//列表操作按钮高度，默认是0，如果要显示操作按钮，需要在显示之前改变这个高度

            listButtons: [],//列表的操作按钮
            //listEngine的默认参数，该对象将会把从父类收到的参数和自己的默认参数合并，然后传递给gridTable控件
            listParam: {idField:"F_ID",isShowFixCol: true,isShowBtnGroup: true, isShowQueryParam: true,autoLoad:false,showTitle:true,showToolBar:false},
            exportType:0//导出类别
        })

        const init=()=> {//合并替换参数
            //设置表格标题
            let title='';
            if(pageParams.title){
                title=pageParams.title;
            }else if(pageParams.modelComp)title=pageParams.modelComp.title;
            if(''==title){
                title=route.meta.title;
            }
            dataObj.listParam = Object.assign({},dataObj.listParam, pageParams,{title:title});
            dataObj.btnGroupHeight=dataObj.listParam.isShowBtnGroup?41:0;//计算按钮组高度
            let conditionRowNum = 0;//计算查询条件内容高度
            let rowHeight=39;//默认是small的高度
            let elementUISize=localStorage.getItem("elementUISize");
            switch (elementUISize) {//根据当前组件大小动态改变每行的高度,没有设置过大小的话，默认是small（39），就不会进入switch
                case 'large':rowHeight=46;break;
                case 'medium':rowHeight=42;break;
                case 'mini':rowHeight=35;break;
            }
            if (dataObj.listParam.isShowQueryParam) {//如果要展示查询条件，那么计算总共查询条件高度
                if(context.slots.queryParams){
                    let queryParams=context.slots.queryParams();
                    conditionRowNum = queryParams.length;
                    // dataObj.queryConditionHeight = conditionRowNum * rowHeight + conditionRowNum * 5 + 5;
                    dataObj.queryConditionHeight = conditionRowNum * rowHeight;
                }
            }else{
                dataObj.queryHeight=0;
            }
        }
        init();
        onMounted(()=>{
            //DOM还没更新
            nextTick(async()=>{
                calTbMaxHeight();
                await loadPageData();//请求后台，为grid和列表页其它数据项赋值
            })
            //监听窗口大小变化，主要是为了设置表格的最大高度
            window.onresize = ()=> {
                if(window.innerHeight){
                    calTbMaxHeight();
                }
            }
        })

        //---------------------------computed---------------------------
        //是否具有删除权限
        const isShowDel=computed(()=>{
            return (row) => {
                let isShow=dataObj.backData.canDel;
                if(parentInst[ISSHOWDEL])isShow=parentInst[ISSHOWDEL](row);
                return isShow;
            };
        })
        //是否具有编辑权限
        const isShowEdit=computed(()=>{
            return (row) => {
                let isShow=dataObj.backData.canEdit || dataObj.backData.canView;
                if(parentInst[ISSHOWEDIT])isShow=parentInst[ISSHOWEDIT](row);
                return isShow;
            };
        })
        //---------------------------computed---------------------------
        //在列表引擎页面不让表格自动加载了，因为如果一个页面有多个表格，就会到后台查询多次，假如把请求数据放到列表引擎，就可以只请求一次，然后把结果给各个表格
        const loadPageData=async()=>{
            let queryParam=pageParams.queryParam;
            //如果不需要查询条件，那么queryParam就等于undefined。就需要动态构建一个queryParam对象给gridTable
            if(!queryParam)queryParam={};
            queryParam.pageSize = tbRef.value.tbPagerInitParam.pageSize;
            queryParam.currentPage = tbRef.value.currentPage;
            queryParam.canPage = tbRef.value.canPage;
            let res = await utils.$$api.postRequest({url: pageParams.modelMethod, params: queryParam});

            if(parentInst[AFTERPAGEDATA]){//业务模块有自己的加载完毕处理事件
                parentInst[AFTERPAGEDATA](res);
            }else{
                if(tbRef.value)tbRef.value.tbData = res.rows;
                if(tbRef.value)tbRef.value.total = res.total;
                if (parentInst[GRIDLOADED]) {//调用业务模块自己的表格加载完毕事件
                    parentInst[GRIDLOADED](res);
                }
            }
            dataObj.backData = res;//存储后台返回的数据
            dataObj.listButtons = res.buttons;//为列表头部的按钮赋值
            dataObj.fullscreenLoading=false;//关闭正在加载效果
        }
        //头部按钮事件
        const clickHandler=(ev)=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if (parentInst[ev]) {
                parentInst[ev]();
            } else {
                proxy[ev]();
            }
        }
        //查询按钮事件
        const queryHandler=()=>{
            if (parentInst[QUERYHANDLER]) {
                parentInst[QUERYHANDLER]();
            } else {
                tbRef.value.queryHandler(true);
            }
        }
        //新增按钮事件
        const addHandler=async ()=>{
            if (parentInst[ADDHANDLER]) {
                parentInst[ADDHANDLER]();
            } else {
                await showModule({addOrEdit: 'add', id: '',dialogDiv:utils.$$str.randomString(6)});
            }
        }
        //编辑事件
        const editHandler=async (row)=>{
            if (parentInst[EDITHANDLER]) {
                parentInst[EDITHANDLER](row);
            } else {
                await showModule({addOrEdit: 'edit', id: row[dataObj.listParam.idField],dialogDiv:utils.$$str.randomString(6)});
            }
        }
        //新增和编辑的时候，弹出框
        const showModule=async(options)=>{
            options = Object.assign({}, {ownerComp: proxy}, dataObj.listParam,options);
            let vm = await utils.$$dialog.create(options);
            vm.dialogVisible = true;
        }
        //dialog弹出框确认回调方法，返回false不会关闭弹出框
        const sureHandler=(modelEngineRef)=>{
            if (parentInst[SUREHANDLER]) {
                return parentInst[SUREHANDLER](modelEngineRef);
            }else{
                return true;
            }
        }
        //dialog弹出框取消回调方法，返回false不会关闭弹出框
        const cancelHandler=(modelEngineRef)=>{
            if (parentInst[CANCELHANDLER]) {
                return parentInst[CANCELHANDLER](modelEngineRef);
            }else{
                return true;
            }
        }
        //---------------导出excel---------------
        const sureExport=async()=>{
            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            switch (dataObj.exportType) {
                case 0:
                    await exportExcel(tbRef.value.getTbData());
                    break;
                case 1:
                    let modelPathArr=dataObj.listParam.modelMethod.split('/');
                    let modelPath="/"+modelPathArr[1];
                    let res = await utils.$$api.postRequest({url: modelPath + "/exportExcelData", params: {canPage: false}});
                    await exportExcel(res.rows);
                    break;
            }
            dataObj.exportExcelVisible = false;
            loading.close();
            proxy.$message({showClose: true, message: '导出成功', type: 'success'});
        }
        //jsonData只保留fields里面有的列
        const formatJson=(fields, jsonData)=>{
            return jsonData.map(rowData => fields.map(field => rowData[field]))
        }
        const exportExcel=(tbData)=>{
            return new Promise((resolve, reject) => {
                const data = formatJson(tbRef.value.tbCols.fields, tbData);//处理之后的tbData
                let fields=tbRef.value.tbCols.fields;
                let labels=tbRef.value.tbCols.labels;
                if (parentInst[EXPORTEXCEL]) {
                    parentInst[EXPORTEXCEL](fields, labels,data,proxy);
                    resolve();
                } else {
                    //导出数据核心就下面一行代码
                    proxy.excelUtils(labels, data, dataObj.listParam.title + "电子表格");
                    resolve();
                }
            }).catch(err => {
                console.log('导出excel出错了')
            });
        }
        //列表头部的导出按钮事件
        const exportExcelHandler=()=>{
            dataObj.exportExcelVisible = true;
        }
        //---------------导出excel---------------
        //列表固定列删除链接事件
        const deleteHandler=(row)=>{
            if (parentInst[DELETEHANDLER]) {
                parentInst[DELETEHANDLER](row);
            } else {
                utils.$$tools.configBox({
                    fn:async ()=> {
                        await doDelete(row)
                    }
                });
            }
        }
        //删除，向后台发送请求
        const doDelete=async(row)=>{
            if (parentInst[BEFOREDELETEHANDLER] && !parentInst[BEFOREDELETEHANDLER](row)) {//抛出删除之前接口
                return;
            }
            //注意：这里可能会有问题，/corp/pageData,下面的处理是为了得到/corp，假如后台的controller叫做/corp/abc呢？所以这里要配合着后台来用
            let modelPathArr=dataObj.listParam.modelMethod.split('/');
            let modelPath="/"+modelPathArr[1];
            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let res = await utils.$$api.postRequest({url: modelPath + "/delete", params: {id: row[dataObj.listParam.idField]}});
            loading.close();
            if(res.result){
                utils.$$tools.success({message: res.msg});
                if (parentInst[AFTERDELETEHANDLER]) parentInst[AFTERDELETEHANDLER](row);//抛出删除之后接口
                //删除成功之后，重新加载表格,两种写法，第一种是回到第一页，第二种是留在当时的页码
                // queryHandler(true);
                tbRef.value.queryHandler(false);
            }
        }
        //折叠面板事件
        const handleChange=(val)=> {
            let conditionHeight = dataObj.listParam.isShowQueryParam ? dataObj.queryConditionHeight : 0;
            if (val.length != 1) { //==1表示展开
                conditionHeight = 0;
                dataObj.queryHeight=31;
            }
            dataObj.queryHeight=dataObj.queryHeight+conditionHeight;
            calTbMaxHeight();
        }
        const setTbData=(tbData)=>{//设置表格数据
            tbRef.value.setTbData(tbData);
        }
        const getTbData=()=> {//得到表格数据
            return tbRef.value.getTbData();
        }
        const getTbInst=()=>{//得到表格引用
            return tbRef.value;
        }
        //重新计算设置表格最大高度
        const calTbMaxHeight=()=> {
            let page=32;//底部分页工具栏高度是32
            if(!tbRef.value.canPage){
                page=0;//如果不分页，则分页条高度置为0
            }
            let maxTbHeight=(window.innerHeight-dataObj.btnGroupHeight-dataObj.queryHeight-utils.$$const.baseVar.topHeight-utils.$$const.baseVar.tabHeight-page);
            if(dataObj.listParam.showTitle)maxTbHeight=maxTbHeight-utils.$$const.baseVar.tbHeaderHeight;
            if(dataObj.listParam.showToolBar)maxTbHeight=maxTbHeight-utils.$$const.baseVar.tbToolBarHeight;
            if(dataObj.listParam.reduceOthers)maxTbHeight=maxTbHeight-dataObj.listParam.reduceOthers;
            tbRef.value.setMaxTbHeight(maxTbHeight);
        }
        //设置列表指定id按钮是否可用
        const setBtnEnable=(val,id)=>{
            if(!id)id='add';//默认是新增按钮
            let btn=dataObj.listButtons.find((btn) => btn.id==id);
            if(btn)btn.disabled=val;
        }
        const hasOperatePermission=()=>{
            return (dataObj.backData.canDel || dataObj.backData.canEdit || dataObj.backData.canView)
        }
        return{
            ...toRefs(dataObj),isShowDel,isShowEdit,tbRef,loadPageData,queryHandler,addHandler,
            sureHandler,cancelHandler,clickHandler,editHandler,deleteHandler,sureExport,exportExcelHandler,
            handleChange,setTbData,getTbData,getTbInst,calTbMaxHeight,setBtnEnable,hasOperatePermission
        }
    }
});
export default listEngine;
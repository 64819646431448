import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const CustomerAddressCardHelper = defineComponent({
    name:'CustomerAddressCard',
    title:'客户地址',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            provinceData:[],
            cityData:[],
            areaData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/customerAddress"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                contacts: [
                    {required: true, message: "请输入联系人", trigger: "blur" }
                ],
                telephone: [
                    { required: true,validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                address: [
                    {required: true, message: "请输入联系地址", trigger: "blur" }
                ],
                provinceId: [
                    {required: true, message: "请选择省", trigger: "blur" }
                ],
                areaId:[
                    {required: true, message: "请选择所在区", trigger: "blur" }
                ]
            }
        })
        onMounted(async ()=>{
            await buildProvinceData();

        })

        const buildProvinceData=async ()=>{
            dataObj.provinceData= await utils.$$api.getProvinces({});//表头省
        }
        const buildCityData=async (provinceId)=>{
            dataObj.cityData = await utils.$$api.getCitiesByProvince({provinceId:provinceId});//表头城市
        }
        const buildAreaDataByCity=async (cityId)=>{
            dataObj.areaData = await utils.$$api.getAreasByCity({cityId:cityId});//明细按城市加载区域
        }
        const buildAreaDataByProvince=async (provinceId)=>{
            dataObj.areaData = await utils.$$api.getAreasByProvince({provinceId:provinceId});//明细按省加载区域
        }
        //---------------------------computed---------------------------
        const selectOnChange=async(newValue,selectId)=>{
            if('cityId'==selectId){}
            if('provinceId'==selectId){
                dataObj.form.cityId='';
                dataObj.form.areaId='';
                dataObj.cityData=[];
                dataObj.areaData=[];
                await buildCityData(newValue);
                await buildAreaDataByProvince(newValue);
            }
            if('cityId'==selectId){
                dataObj.form.areaId='';
                dataObj.areaData=[];
                await buildAreaDataByCity(newValue);
            }
        }
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'provinceId'==params.comboId){
                    return dataObj.provinceData;
                }
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData;
                }
                if(params && 'areaId'==params.comboId){
                    return dataObj.areaData;
                }
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'卸货点'},{value:1,label:'装货点'}]
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,engineInst)=>{//打开客户地址弹出框选择地址的时候，会传入客户id
            // console.log(engineInst.engineParams.queryParam.customerId);
            dataObj.form.customerId=engineInst.engineParams.queryParam.customerId;
            if('/load'==addOrLoad){
                await buildCityData(data.data.provinceId);
                await buildAreaDataByCity(data.data.cityId);
            }
            // dataObj.areaData=[];
            // data.resultData.forEach((item,index)=>{
            //     dataObj.areaData.push({value:item.id,label:item.name,item:item});
            // })
        }
        const setParam=(engineInst)=>{
            return{customerId:engineInst.engineParams.queryParam.customerId}
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            // if(dataObj.form.coordinate){}
            return true;
        }
        //调用地图弹出框选择地址
        const chooseAddress=async ()=>{
            await utils.$$lghdUtils.buildConfigAddressDialog({proxy:proxy});
        }
        //地图弹出框回调事件，在这可以拿到弹出框的实例，里面有选择的地址坐标等信息
        const sureHandler=async (modelInst)=>{
            dataObj.form.coordinate=modelInst.coordinate;
            dataObj.form.address=modelInst.searchContent;
            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler,chooseAddress,sureHandler
         
        }
    }
});
export default CustomerAddressCardHelper;
<template>
    <div class="myMap">
        <div>
            <el-input @input="e => searchContent = validForbid(e)" v-model="searchContent" placeholder="请输入要搜索的地址" clearable @keyup.enter.native="searchHandler">
                <template #append>
                    <el-button @click="searchHandler">搜索</el-button>
                </template>
            </el-input>
        </div>
        <div id="container" tabindex="0" style="width: 100%;height: 100%;" ref="mapInstRef"></div>
    </div>
</template>

<script>
    import {ref,reactive,toRefs, computed,defineComponent,onBeforeMount,onMounted,nextTick,getCurrentInstance} from 'vue';


    export default defineComponent ({
        name: "ChooseAddress",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;

            let dataObj=reactive({
                index:props.engineParams?props.engineParams.index:'',
                id:props.engineParams?props.engineParams.id:'',
                from:'',
                coordinate:[],
                searchContent:'',
                mapInst:'',//地图实例
                AMap:'',//地图实例
                geocoder:'',//坐标解析实例
                searchAutoComplete:''//搜索实例
            })
            const initMap=async ()=>{
                let mapResult=await proxy.utils.$$map.buildMapInst({});
                dataObj.AMap=mapResult.AMap;
                dataObj.mapInst=mapResult.mapInst;
                dataObj.searchAutoComplete=mapResult.searchAutoComplete;
                dataObj.geocoder=mapResult.geocoder;

                //地图点击时，获取点击地经纬度
                dataObj.mapInst.on('click', function (e) {
                    mapClick(e);
                })
            }
            //地图点击处理，根据点击的坐标获取省市和完整地址
            const mapClick=(e)=>{
                proxy.utils.$$map.getAddressByLngLat({lngLat:[e.lnglat.lng,e.lnglat.lat],geocoder:dataObj.geocoder,fn:(options)=>{
                        dataObj.coordinate=[e.lnglat.lng,e.lnglat.lat];
                        dataObj.searchContent=options.address;
                    }});
            }
            onBeforeMount(()=>{
                initMap();
            })
            onMounted(async ()=>{})
            //根据内容搜索,把所搜到的第一个坐标点标记在地图上
            const searchHandler=()=>{
                if(dataObj.searchContent){
                    const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    // 根据关键字进行搜索
                    dataObj.searchAutoComplete.search(dataObj.searchContent, function(status, result) {
                        // 搜索成功时，result即是对应的匹配数据
                        // console.log(result);
                        let newCenter=[];let flag=true;let point0Name='';
                        let map=new Map();//把经度维度放到map中
                        if(status=='no_data'){
                            loading.close();
                            proxy.$message('没有找到任何匹配项');
                            return;
                        }
                        if(result && result.tips){
                            let tips=result.tips;
                            let len=tips.length;
                            if(len>5)len=5;//只取搜索的5个点
                            for(let i=0;i<len;i++){
                                if(flag){
                                    newCenter[0]=tips[i]['location']['lng'];
                                    newCenter[1]=tips[i]['location']['lat'];
                                    point0Name=tips[i].name;
                                    flag=false;
                                }
                                map.set(tips[i]['location']['lng'],tips[i]['location']['lat']);
                            }
                        }
                        if(newCenter[0] && newCenter[1])
                        proxy.utils.$$map.buildMarker({lng:newCenter[0],lat:newCenter[1],title:point0Name,AMap:dataObj.AMap,mapInst:dataObj.mapInst});//把第一个点标记在地图上
                        dataObj.mapInst.setCenter(newCenter,true,500);
                        dataObj.coordinate=[newCenter[0],newCenter[1]];
                        loading.close();
                    })
                }
            }
            return{
                ...toRefs(dataObj),searchHandler
            }
        }
    });

</script>

<style scoped>
    .myMap{
        width: 100%;
        height: calc(100% - 32px);
    }
    .myMap::v-deep(.amap-marker-label) {
        border-radius: 5px;
        opacity: 0.8;
        background: #86857D;
        font-size: 12px;
        color: white;
        border: 0;
    }
    .leftSpan{
        width: 30px;text-align: right;padding-right: 15px;
    }
</style>
<template>
    <div class="CustomerAddress">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="联系人" prop="contacts">
                <el-input @input="e => form.contacts = validForbid(e)" v-model="form.contacts" placeholder="请输入联系人" maxlength="25" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="联系电话" prop="telephone">
<!--                <el-input-number v-model="form.telephone" placeholder="请输入联系电话" :disabled="disabled" style="width: 100%" maxlength="13"/>-->
                <el-input @input="e => form.telephone = validForbid(e)" v-model="form.telephone" placeholder="请输入联系电话" maxlength="13" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="所在省" prop="provinceId">
                <el-select :ref="el=>refMap.set('provinceId',el)" v-model="form.provinceId" clearable placeholder="请选择所在省" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'provinceId')}">
                    <el-option v-for="item in provinceData" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="所在市" prop="cityId">
                <el-select :ref="el=>refMap.set('cityId',el)" v-model="form.cityId" clearable placeholder="请选择所在市" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cityId')}">
                    <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="所在区" prop="areaId">
                <el-select :ref="el=>refMap.set('areaId',el)" v-model="form.areaId" clearable placeholder="请选择所在区" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'areaId')}">
                    <el-option v-for="item in areaData" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="地址类别" prop="type">
                <el-select :ref="el=>refMap.set('type',el)" v-model="form.type" clearable placeholder="请选择地址类别">
                    <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="联系地址" prop="address">
                <el-input v-model="form.address" placeholder="请选择联系地址" maxlength="122" :disabled="true"/>
                <el-button size="mini" @click="chooseAddress()">选择地址</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>
    import CustomerAddressCardHelper from "./CustomerAddressCardHelper.js";
    export default CustomerAddressCardHelper;
</script>

<style scoped>
    .CustomerAddress{
        width: 100%;
    }
</style>

<template>
    <div id="pageList" class="CustomerAddressList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.contacts = validForbid(e)" placeholder="请输入联系人进行模糊匹配查询..." v-model="pageList.queryParam.contacts" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系电话：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.telephone = validForbid(e)" placeholder="请输入联系电话进行模糊匹配查询..." v-model="pageList.queryParam.telephone" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系地址：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.address = validForbid(e)" placeholder="请输入联系地址进行模糊匹配查询..." v-model="pageList.queryParam.address" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:fixCol v-if="engineParams && 'chooseUnloadAddress'==engineParams.from">
                <el-table-column type="selection" width="55"></el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_ADDRESS" label="联系地址"/>
                <el-table-column prop="F_AREA_NAME" label="所属区域"/>
                <el-table-column prop="F_TYPE_NAME" label="地址类型"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
                <el-table-column prop="F_COORDINATE" label="地址坐标"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import CustomerAddressCard from './CustomerAddressCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CustomerAddressList",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                        customerId:props.engineParams?props.engineParams.customerId:'',
                        type:props.engineParams && props.engineParams.from?props.engineParams.from:'',
                        wayBillCarId:props.engineParams && props.engineParams.wayBillCarId?props.engineParams.wayBillCarId:'',
                        contacts:"",
                        telephone:"",
                        address:""
                    },
                    modelComp: CustomerAddressCard,
                    modelMethod: "/customerAddress/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------

            return{
                ...toRefs(dataObj)
            }
        }
    });
</script>

<style scoped>
    .CustomerAddressList{
        width: 100%;
    }
</style>